export const palm_product_constant = [
    {text:"Great Nutrition for babies and childrens"},
    {text:"boost your immunity and strength"},
    {text:"Great substitute to children chocolates"},
    {text:"Rich source of vitamins , minerals and Highly nutritions"},
    {text:"Great essentials for hair growth"},
    {text:"Used in traditional Bengali sweets (nolen gur/Patali Gur)"},
    {text:"Good source of iron and fibre"},
    {text:"Balanced diet and healthy life style"},
]

export const  why_choose = [
    {
        heading:"Experienced staff",
        sub_heading:`Our Manufacturing partner CERA(Center for Rural Action), is NGO and works for rural development and empowerment and doing the
        same for last 40 years. To Improve socio-economic conditions of 500 artisans [tappers] and health consciousness among the people by
        improving capacity building, business skills for making value added products and creating marketing channels for CERA`
    },
    {
        heading:"Health and hygeine",
        sub_heading:`though our  palm tress located on remote areas. we make sure
        health and hygenic as top priority reguylar checks on usage of
        pots  gloves and prepartion are completely monitored`
    },
    {
        heading:"Tested and approved",
        sub_heading:`our palm cholcolates, syrup and jaggery are tested and approved
        by major food check agency like fassai`
    },
    {
        heading:"Assured Delivery with well packaging",
        sub_heading:`Assured Delivery with well packaging`
    },
    {
        heading:"Customer Support",
        sub_heading:`We are always pleased to help you with any kind of queries.
        Please call us or email us customercare@vrishkarpalmproducts.com.`
    },
    {
        heading:"Marketing Partner",
        sub_heading:`We integrated with Amazon to have secure payments and smooth delivery and tracking systems`
    },
]

export const experts_says = [
    {
        header:'Relieves common ailments like cold and cough :',
        text:`Date palm jaggery can be used to treat dry cough and cold. It helps to clear the respiratory tract by dissolving the mucus. It is a good home remedy for people suffering from respiratory problems like asthma`,
    },
    {
        header:'Rich in nutrients : ',
        text:`
        The nutrients present in date palm jaggery play a vital role in keeping your body functioning well, which is exactly what you need during winter. Some of the significant minerals found in it are iron (essential for blood production) and magnesium (helps regulate the nervous system).
        ​Helps in weight loss
        Higher count of potassium in date palm jaggery reduces water retention and bloating which helps promote weight loss.
         `},
    {header:'​Boosts energy : ',text:`If you are feeling low on energy on a cold winter day, try eating some date palm jaggery. It is high in composite carbohydrates which help in digesting food faster than the regular sugar. Consuming a piece of this jaggery regularly helps in releasing energy which keeps you energetic and active for many hours.`},
    {header:'Treats menstrual pain:',text:`Date palm jaggery is an effective natural remedy for treating menstrual issues. Its consumption releases endorphins, which calm your body and relieves cramps and stomach aches.
    Restores healthy digestion
    Winter can bring in all kinds of stomach problems, either from sickness or spicy food, which we often crave for in the season. Date palm jaggery acts as a digestive agent and helps to alleviate stomach pain. It activates digestive enzymes inside your stomach and helps clean the intestinal tracts.`}
    ,
    {header:'​Eases joint pain : ',text:`Date palm jaggery strengthens the bones, thereby reducing joint pain and other related problems like arthritis. It is rich in calcium thus making the bones stronger and healthier.`},
    {header:'Ultimate cleanser : ',text:`Palm jaggery cleans up your entire body system by flushing out all the toxins from the body and keep your body healthy. It also helps in cleansing the respiratory tract, intestines, food pipe, lungs and stomach.`},
]

export const nutrition_table_constants = [
    {
        parameter:"Iron g/100ml",
        nutrition:"2.46 mg"
    },
    {
        parameter:"Potassium g/100ml",
        nutrition:"9.8 mg"
    },
    {
        parameter:"Phosphourous g/100ml",
        nutrition:"12.4 mg"
    },
    {
        parameter:"Calcium g/100ml",
        nutrition:"48.6 mg"
    },
    {
        parameter:"Vitamin C g/100ml",
        nutrition:"34.2 ug"
    },
    {
        parameter:"Fructose g/100ml",
        nutrition:"3.9 g"
    },
    {
        parameter:"Carbohydrates g/100ml",
        nutrition:"73.6 g"
    },
    {
        parameter:"Total Fat g/100ml",
        nutrition:"0.00 g"
    },
    {
        parameter:"Energy g/100ml",
        nutrition:"321.6 kcal"
    },
    {
        parameter:"Sodium g/100ml",
        nutrition:"3.8 mg"
    },
    {
        parameter:"Cholesterol g/100ml",
        nutrition:"0.00 mg"
    },
    {
        parameter:"Protein g/100ml",
        nutrition:"1.9 g"
    }
]

export const palm_nutrition_constants = [
    {
        header:"Good for hair",
        text:`Iron is abundant in jaggery, preventing hair loss. Iron aids in synthesizing haemoglobin, which improves blood flow to the scalp's roots and encourages hair growth while minimizing hair loss.`
    },
    {
        header:"Relieves common ailments like cold and cough",
        text:`Date palm jaggery can be used to treat dry cough and cold. It helps to clear the respiratory tract by dissolving the mucus. It is a good home remedy for people suffering from respiratory problems like asthma.`
    },
    {
        header:"Rich in nutrients",
        text:`The nutrients present in date palm jaggery play a vital role in keeping your body functioning well, which is exactly what you need. Some of the significant minerals found in it are iron (essential for blood production) and magnesium (helps regulate the nervous system).`
    },

    {
        header:"​Boosts energy",
        text:`Try eating some date palm jaggery. It is high in composite carbohydrates which help in digesting food faster than the regular sugar. Consuming a piece of this jaggery regularly helps in releasing energy which keeps you energetic and active for many hours.`
    },
    {
        header:"Restores healthy digestion",
        text:`Date palm jaggery acts as a digestive agent and helps to alleviate stomach pain. It activates digestive enzymes inside your stomach and helps clean the intestinal tracts.`
    },
 
]


export const available_itmes = [
    {
        text:"Palm Jaggery Liquid"
    },
    {
        text:"Jaggery"
    },
    {
        text:"Sweets"
    },
    {
        text:"Syrup"
    },
    {
        text:"Gift Boxes"
    },
]

export const productDetailsConst = [
    {
        heading_text:'Specialty',
        sub_text:'Vegetaian'
    },
    {
        heading_text:'Weight',
        sub_text:'250.00 Grams, 500.00 Grams, 750.00 Grams'
    },
    {
        heading_text:'Ingredient Type',
        sub_text:'Indian Wild Date Palm Jaggery'
    },
    {
        heading_text:'Brand',
        sub_text:'Vrishkar Palm Products'
    },
    {
        heading_text:'Item Package Quantity	',
        sub_text:'1'
    },
    {
        heading_text:'Form',
        sub_text:'Liquid'
    },
    {
        heading_text:'Manufacturer',
        sub_text:'CERA Organisation'
    },
    {
        heading_text:'Allergen Information',
        sub_text:'Dairy'
    },
    {
        heading_text:'Product Dimensions',
        sub_text:'17 x 06 x 03 cm'
    },
    {
        heading_text:'Country of Origin',
        sub_text:'India'
    },
]

export const productDetails = [
    {
        heading_text:'Colour',
        text:'Palm Jaggery is rich dark colour'
    },
    {
        heading_text:'Expiry',
        text:'None.'
    },
    {
        heading_text:'Generic Name',
        text:'Palm Jaggery Liquid'
    },
    {
        heading_text:'Packer',
        text:'Vrishkar Palm Products.'
    },
    {
        heading_text:'Package Information',
        text:'Palm Jaggery Box'
    },
    {
        heading_text:'Preservatives,Sugar',
        text:'None'
    },
  
]