// import dark_heart_chocolate from './assets/images/dark_heart_chocolate.png'
import jaggeryImage from './assets/images/PalmJaggery/Palm_Jaggery_Main.png';
import nectarImage from './assets/images/Palmjaggerynectar.png';

export const products = [
    {
        "product_id":"64a66d46-1344-11ed-861d-0242ac120001",
        "product_name":"Vrishkar Palm Jaggery | Date Palm Jaggery | Palm Sugar | Organic Palm Candy / Palm Cubes | Patali Gur/ Nolen Gur | 250 Grams",    
        "type":"Pure Dark  Jagger Chocolate",
        "category":"Chocolates",
        "quantity":"250 Grams",
        "redirectUrl":"https://www.amazon.in/dp/B0BTXJQ9C5",
        "picSrc": jaggeryImage,
        "price":476
    },
    {
        "product_id":"64a66d46-1344-11ed-861d-0242ac12000",
        "product_name":"Vrishkar Palm Jaggery Liquid | Date Palm Jaggery Syrup | Palm Sugar Liquid | Organic Palm Candy / Palm Cubes Syrup | Patali Gur/ Nolen Gur Liquid | 250 Grams",   
    
        "type":"Pure Dark  Jagger Chocolate",
        "category":"Chocolates",
        "quantity":"250 Grams",
        "redirectUrl":"https://www.amazon.in/dp/B0BZT51N36",
        "picSrc": nectarImage,
        "price":476
    },
    {
        "product_id":"64a66d46-1344-11ed-861d-0242ac12000",
        "product_name":"Vrishkar Palm Jaggery Liquid | Date Palm Jaggery Syrup | Palm Sugar Liquid | Organic Palm Candy / Palm Cubes Syrup | Patali Gur/ Nolen Gur Liquid | 500 Grams",   
        "type":"Pure Dark  Jagger Chocolate",
        "category":"Chocolates",
        "quantity":"500 Grams",
        "redirectUrl":"https://www.amazon.in/dp/B0BZT3VH9X",
        "picSrc": nectarImage,
        "price":896
    },
    {
        "product_id":"64a66d46-1344-11ed-861d-0242ac120001",
        "product_name":"Vrishkar Palm Jaggery | Date Palm Jaggery | Palm Sugar | Organic Palm Candy / Palm Cubes | Patali Gur/ Nolen Gur | 500 Grams",  
        "type":"Pure Dark  Jagger Chocolate",
        "category":"Chocolates",
        "quantity":"500 Grams",
        "redirectUrl":"https://www.amazon.in/dp/B0BVKHQ4GW?ref=myi_title_dp",
        "picSrc": jaggeryImage,
        "price":896
    },
    {
        "product_id":"64a66d46-1344-11ed-861d-0242ac120001",
        "product_name":"Vrishkar Palm Jaggery Liquid | Date Palm Jaggery Syrup | Palm Sugar Liquid | Organic Palm Candy / Palm Cubes Syrup | Patali Gur/ Nolen Gur Liquid | 750 Grams",
        "type":"Pure Dark  Jagger Chocolate",
        "category":"Chocolates",
        "quantity":"750 Grams",
        "redirectUrl":"https://www.amazon.in/dp/B0BVJHQ457?ref=myi_title_dp",
        "picSrc": jaggeryImage,
        "price":1196
    },
    {
        "product_id":"64a66d46-1344-11ed-861d-0242ac12000",
      
        "product_name":"Vrishkar Palm Jaggery | Date Palm Jaggery | Palm Sugar | Organic Palm Candy / Palm Cubes | Patali Gur/ Nolen Gur | 750 Grams",   
        "description":"The fresh sap is boiled to make palm dark jagger chocolates",
        "type":"Pure Dark  Jagger Chocolate",
        "category":"Chocolates",
        "quantity":"750 Grams",
        "redirectUrl":"https://www.amazon.in/dp/B0BZT8MFJP",
        "picSrc": nectarImage,
        "price":1196
    },
    
]