import { experts_says } from "../../constants";
import doctor_img from "../../assets/images/doctor_img.svg";
import leafe_icon from "../../assets/images/leafe_icon.svg";
import the_natural_taste_of_india from "../../assets/images/the_natural_taste_of_india.png";
// import './LandingPage.scss'
import '../LandingPage/LandingPage.scss'

export const AmazingHealthPage = () => {
  return (
    <div className='banner_container'>
    <div>
      <div className="secondary_color p-4 experts_says ">
        <h1 className="ft-w8 fa-c mt-4 mb-4">
          Top 10 Health Benefits Of Palm Jaggery
        </h1>

        <div className="content_flex content-flex align-items-center mb-screen py-4">
          <img className="experts_says__img" src={doctor_img} alt="" />
          <div>
            {experts_says.map((e: any, index: any) => (
              <div className="content_flex content_item_fs">
                <img className="leafe_img mx-2" src={leafe_icon} alt="" />
                <div>
                  <p className="ft-w6">{e.header} </p>
                  <p>{e.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

    
      </div>
      <div className="the-tast_of_india fa-c ">
          <img src={the_natural_taste_of_india} alt="" />
        </div>
    </div>
    </div>
  );
};
