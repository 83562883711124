import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
 
export const TestProductPage = () => {
  const [todos, setTodos] = useState([{}]);
  const addDataToStroage = (price: any, productId: any) => {
    if (localStorage.getItem("products") === null) {
      localStorage.setItem(
        "products",
        JSON.stringify([{ ProductId: productId, Quantity: 1, Price:price }])
      );
      setTodos([{ ProductId: productId, Quantity: 1,Price:price }]);
    } else {
      const data: any = localStorage.getItem("products");
      const value: any = JSON.parse(data);
      value.push({ ProductId: productId, Quantity: 1, Price:price });
      localStorage.setItem("products", JSON.stringify(value));
      setTodos(value);
    }
    console.log(localStorage.getItem("products"));
  };

  const makeApiCall = async () => {
    localStorage.clear()
    setTodos([{}]);
   const config = {
    headers: {"Content-Type": 'application/json' }
   
  };
 
   const userData:any  = await axios.post(`http://localhost:4000/create-checkout-session`, { data: todos }, config);
   console.log("called at api" +  JSON.stringify(userData.data.url));
   localStorage.clear()
   setTodos([{}]);
    window.location.href = userData.data.url
   
  };

  useEffect(() => {
  
    const values: any = localStorage.getItem("products");
    setTodos(values);

     console.log(`You clicked ${values} times`);
  });

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                <b> badam chocolate</b>
              </Typography>

              <Typography variant="body2">300</Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  addDataToStroage(Number(300 * 100), "123");
                }}
              >
                Add To Shop
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                <b> pista chocolate</b>
              </Typography>

              <Typography variant="body2">600</Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  addDataToStroage(Number(600 * 100), "1234");
                }}
              >
                Add To Shop
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                <b> ghee chocolate</b>
              </Typography>

              <Typography variant="body2">900</Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  addDataToStroage(Number(900*100), "12345");
                }}
              >
                Add To Shop
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                <b> peanut chocolate</b>
              </Typography>

              <Typography variant="body2">1200</Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  addDataToStroage(Number(1200*100), "12378");
                }}
              >
                Add To Shop
              </Button>
            </CardActions>
          </Card>

        
        </Grid>
      </Grid>
      <div>
      <div> {JSON.stringify(todos)}</div>

        <Button variant="contained" color="success" onClick={makeApiCall}>
          Checkout
        </Button>
      </div>
    </div>
  );
};

// export default LandingPage;
