
function NotFoundPage() {
  return (
    <div className="banner_container" style={{ height: '1080px' }}>
      <div>
        <div className="secondary_color p-4 experts_says"  style={{ height: '1000px' }} >
          <h1 className="ft-w8 fa-c mt-4 mb-4" >
            404  Not Found
          </h1>
          <p className="ft-w6">
            
            The page you are looking for doesn't exist or an other
          occurred Go back, or head over to https://www.vrishkarpalmproducts.com/ to choose a new 
          direction


           </p>
          <p className="ft-w6">We are really sorry we can serve you. Head over to our https://www.vrishkarpalmproducts.com/.
          Our palm jaggery, Palm jaggery syrup and palm jaggery chikkis will definitely not disappoint you. </p>

        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
