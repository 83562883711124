import {
    TextField, Button, Grid, Card, CardContent, FormControl, InputLabel,
    Select, MenuItem, OutlinedInput, InputAdornment
} from "@mui/material";
import { PasswordTextFieldComp } from "./PasswordTextFieldComp";
// import { emailRegex } from "../constants/RegExp";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
// import { errorMsg } from "../constants/errorMsg";
import './SignUpPage.scss'
import { emailRegex, errorMsg } from "../../constants/errors";

export const SignUpForm = ({
    emailError,
    emailSuccess,
    addressApiError,
    showBox,
    error,
    newAddress,
    newAdd,
    setNewAdd,
    setAddSelect,
    setHouseNum,
    setPostCode,
    onSubmit,
    Address,
    onChangeHandler,
    register,
    handleSubmit,
    errors,
    houseNum,
    postcodes,
    setAddStat,
    apiAddresData,
    boxs,
    addSelect,
    setBoxs,
    singleAddCard,
    apiPostCode,
    setShowBox,
    setSingleAddCard,
    setApiAddresData,
    multiAdd,
    setMultiAdd
}: any) => {


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
                className='singup_page__text-field'
                label="First Name"
                variant='outlined'
                fullWidth
                {...register("first_name", { required: true })}
            />
            {errors.first_name && <p className="error_msg reg_error">This field is required</p>}
            <TextField
                className='singup_page__text-field'
                label="Last Name"
                variant='outlined'
                fullWidth
                {...register("last_name", { required: true })}
            />
            {errors.last_name && <p className="error_msg reg_error">This field is required</p>}
            <TextField
                className='singup_page__text-field'
                label="Mobile Number"
                variant='outlined'
                fullWidth
                {...register("mobile_number", { required: true })}
            />
            {errors.mobile_number && <p className="error_msg reg_error">This field is required</p>}
            <div className="singup_page__email-container">
                <TextField
                    className='singup_page__text-field'
                    label="Mail Address"
                    variant='outlined'
                    fullWidth
                    {...register("email_id", {
                        required: errorMsg,
                        pattern: {
                            value: emailRegex,
                            message: "Enter valid Email Id",
                        }
                    })}
                    onChange={onChangeHandler}
                />
                <div className="icons">
                    {emailError ? <ErrorOutline color="error" /> : ""}
                    {emailSuccess ? (
                        " "
                    ) : (
                        <CheckCircleOutlineOutlined color="success" />
                    )}
                </div>
            </div>
            {errors.email_id && <p className="error_msg reg_error">{errors.email_id.message}</p>}
            
            {showBox && (
                <>
                    <TextField
                        fullWidth
                        label="House No/Name"
                        id="houseNumber"
                        className='singup_page__text-field'
                        {...register("houseNumber", { required: errorMsg })}
                        value={houseNum}
                        onChange={(e: any) => setHouseNum(e.target.value)}
                    />
                    {errors.houseNumber && <p className="error_msg reg_error">This field is required</p>}
                    <TextField
                        fullWidth
                        label="State"
                        id="houseNumber"
                        className='singup_page__text-field'
                        {...register("state", { required: errorMsg })}
                    />
                    {errors.state && <p className="error_msg reg_error">This field is required</p>}
                    <TextField
                        fullWidth
                        label="District"
                        id="houseNumber"
                        className='singup_page__text-field'
                        {...register("district", { required: errorMsg })}
                    />
                    {errors.district && <p className="error_msg reg_error">This field is required</p>}
                </>
            )}
            {newAdd && (
                <>
                    <TextField
                        fullWidth
                        
                        label="Street"
                        className='singup_page__text-field'
                        {...register("street", { required: true })}
                    />
                    {errors.street && <p className="error_msg reg_error">This field is required</p>}
                    <TextField
                        fullWidth
                        label="City/Town"
                        className='singup_page__text-field'
                        {...register("city_or_town", { required: true })}
                    />
                    {errors.city_or_town && <p className="error_msg reg_error">This field is required</p>}
                    <TextField
                        fullWidth
                        label="Country"
                        className='singup_page__text-field'
                        {...register("country", { required: true })}
                    />
                    {errors.country && <p className="error_msg reg_error">This field is required</p>}
                </>
            )}
            {showBox && (
                <>
                    <FormControl
                        // sx={{ m: 1, width: "25ch" }}
                        className="button_with_textfield singup_page__text-field"
                        fullWidth
                        variant="outlined" >
                        <InputLabel htmlFor="outlined-adornment-password">
                            Postcode
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            {...register("postcode", { required: errorMsg })}
                            value={postcodes}
                            onChange={(e: any) => setPostCode(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    {!newAdd && (
                                        <Button
                                            onClick={() => Address()}
                                            variant="outlined" size="large" className="address_find_btn" >
                                            click
                                        </Button>
                                    )}
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    {errors.postcode && <p className="error_msg reg_error">This field is required</p>}
                    {newAddress && !newAdd && (
                        <p className="error_msg reg_error">
                            Unfortunately, we are returning too many matches for this
                            address. Please refine your search, or{" "}
                            <span
                                style={{ fontWeight: "bold", cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={() => {
                                    setNewAdd(true);
                                    setAddStat(true);
                                }}>
                                Enter manually
                            </span>
                        </p>
                    )}

                    {addressApiError ? (
                        <p className="error_msg reg_error">
                            Enter valid postCode or houseNumber
                        </p>
                    ) : (
                        ""
                    )}
                    {error ? <p className="error_msg reg_error">Please choose Address</p> : ""}

                </>)
            }


            <FormControl fullWidth
                className="singup_page__text-field">
                {apiAddresData.length > 1 && boxs && (
                    <>
                        <InputLabel id="demo-simple-select-label">Address</InputLabel>
                        <Select
                            label="Address"
                            // {...register("address", { required: errorMsg })}
                            value={addSelect}
                            onChange={(e: any) => {
                                const val = e.target.value.toString();
                                setAddSelect(val.split(","));
                                setBoxs(false);
                            }}
                        >
                            {apiAddresData.map((e: any, indx: any) => {
                                const value = `${e.line_1},${e.building_name},${e.town_or_city},${e.county}, ${apiPostCode}`;
                                return (
                                    <MenuItem key={indx} value={value}>
                                        {value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </>
                )}
            </FormControl>

            {errors.address && (
                <span className="errors">{errors.address.message}</span>
            )}

            {apiAddresData.length === 1 && singleAddCard ? (
                <Card className="singup_page__card" >
                    <CardContent>
                        <Grid container>
                            <Grid item xs={8}>
                                {apiAddresData.map((e: any, indx: any) => {
                                    return (
                                        <>
                                            <div key={indx} style={{ lineHeight: "10px" }}>
                                                <p>{e.building_number}</p>
                                                <p>{e.building_name}</p>
                                                <p>{e.town_or_city}</p>
                                                <p>{e.locality}</p>
                                                <p>{e.county}</p>
                                                <p>{apiPostCode}</p>
                                            </div>
                                        </>
                                    );
                                })}
                            </Grid>

                            <Grid item xs={4} style={{ textAlign: "right" }}>
                                <Button
                                    onClick={() => {
                                        setShowBox(true);
                                        setSingleAddCard(false);
                                        setPostCode("");
                                        setHouseNum("");
                                        setApiAddresData([]);
                                        setAddSelect([]);
                                    }}
                                >
                                    EDIT
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ) : apiAddresData.length > 1 ? (
                addSelect.length > 0 &&
                multiAdd && (
                    <Card className="singup_page__card">
                        <CardContent>
                            <Grid container>
                                <Grid item xs={8}>
                                    {addSelect.map((e: any, indx: any) => {
                                        return (
                                            <>
                                                <div key={indx} style={{ lineHeight: "10px" }}>
                                                    <p>{e}</p>
                                                </div>
                                            </>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: "right" }}>
                                    <Button
                                        onClick={() => {
                                            setShowBox(true);
                                            setMultiAdd(false);
                                            setPostCode("");
                                            setHouseNum("");
                                            setApiAddresData([]);
                                            setAddSelect([]);
                                        }}
                                    >
                                        EDIT
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )
            ) : (
                ""
            )}
            <div className='singup_page__button_container'>
                <Button className='button_content singup_page__button' type="submit" >Register</Button>
            </div>
        </form>
    )
}