import { useNavigate } from 'react-router-dom'
import './FooterComponent.scss'

export const FooterComponent = () => {
    const navigate = useNavigate()
    return (
        <div className='footer_container'>
            <h4 className='text_color footer_heading'>Vrishkar Services</h4>
            <div className=' content_flex justify_content_sa footer_sub_text mb-view-content'>
                <div className='mb-view' >
                    <p className='text_color'>Useful Links</p>
                    {/* <div className='curoser fs_12 ft-100 mt-1'>
                        Terms & condition
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1' onClick={() => navigate('/privacyPolicy')} >
                        Privacy Policy
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1' onClick={() => navigate('/return_refund_policy')}>
                        Return & Refund Policy
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1' onClick={() => navigate('/ShippingPolicy')}>
                        Shopping policy
                    </div> */}
                    <div className='curoser fs_12 ft-100 mt-1' onClick={()=>navigate('/FAQS')}>
                        FAQ
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1' onClick={()=>navigate('/siteMap')}>
                        Sitemap
                    </div>
                </div>

                <div className='footer_all_text desktop_version'></div>

                <div className='mb-view'>
                    <p className='text_color'>Quick Links</p>
                    <div className='curoser fs_12 ft-100 mt-1'>
                        Track your order
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1'>
                        Abdivut Us
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1'>
                        Contact Us
                    </div>
                </div>

                <div className='footer_all_text desktop_version'></div>

                <div className='mb-view'>
                    <p className='text_color'>Reach us</p>
                    <div className='curoser fs_12 ft-100 mt-1'>
                        Anantapur - 515001
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1'>
                        Andhra Pradesh
                    </div>
                    <div className='curoser fs_12 ft-100 mt-1'>
                      India
                    </div>
                </div>

                <div className='footer_all_text desktop_version'></div>

                <div className='mb-view'>
                    <p className='text_color'>For Order Queries</p>
                    <div className='curoser fs_12 ft-100 mt-1'>
                        <u>customercare@vrishkarpalmproducts.com</u>
                    </div>
                    <p className='text_color'>
                        For Business Queries
                    </p>
                    <div className='curoser fs_12 ft-100 mt-1'>
                        <u>customercare@vrishkarpalmproducts.com</u>
                    </div>
                </div>

            </div>
            <hr />
            <div className='content_flex justify_content_sa'>
                <div>
                    Registered Charity | 1140351
                </div>

                <div>
                    Cookie policy | Accessibility | Privacy policy | Freedom of Information | Modern Slavery Statement | Terms and Condition
                </div>
            </div>
        </div>
    )
}