import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChocolatePage, HeaderNavBar, FooterComponent, NuteritionPage, CheckoutPage, ProductDescriptionPage, ReturnAndRefundPolicy, ShippingPolicy, PrivacyPolicy } from './basmodule'
import { SignUpPage } from "./pages/RegisterPage/SignUpPage";
import { ProductInformationPage } from "./pages/ProductDescriptionPage/ProductInformationPage";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { TestProductPage } from "./modules/TestproductsPage/TestProductPage";
import { FAQsPage } from "./pages/FAQsPage/FAQsPage";
import { AmazingHealthPage } from "./pages/BlogPage/AmazingHealthPage";
import { ProductJaggeryDescriptionPage } from "./pages/ProductDescriptionPage/ProductJaggeryDescriptionPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import SiteMap from "./pages/SiteMap/SitMapPage";
import React from "react";

function App() {
  return (
    <div>
      <div className="">
        <Router>
          <HeaderNavBar />
          <div className="margin_content">
            <Routes>
              {/* <Route path="/" element={<ComingSoonPage />} /> */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/palm-jaggery-nutritional-info" element={<NuteritionPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/return_refund_policy" element={<ReturnAndRefundPolicy />} />
              <Route path="/products" element={<ChocolatePage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/Products/Palm-Jaggery-Liquid" element={<ProductDescriptionPage />} />
              <Route path="/Products/Palm-Jaggery" element={<ProductJaggeryDescriptionPage />} />
              <Route path="/ProductInformation" element={<ProductInformationPage />} />
              <Route path="/products1" element={<TestProductPage />} />
              <Route path="/FAQS" element={<FAQsPage />} />
              <Route path="/404-NotFound" element={<NotFoundPage />} />
              <Route path="/blog/Benefits-Of-Palm-Jaggery" element={<AmazingHealthPage />} />
              <Route path="/siteMap" element={<SiteMap />} />
            </Routes>
          </div>
          <FooterComponent />
        </Router>
      </div>
    </div>
  );
}

export default App;
