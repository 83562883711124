import { productDetails, productDetailsConst } from "../../constants"

export const ProductInformationPage = () => {
    return (
        <>
            <div className='mt-2'>
                <div className='' >
                    <h4 className='ft-w6'>Product Information</h4>
                    <div className="content_flex content_items_c mb-screen mob_block">
                        <div className="nutrition_container__nutrition_table">
                            <table className="table table_container table_left_border">
                                <tbody>
                                    {productDetailsConst.map((e: any) => {
                                        return <tr>
                                            <th >{e.heading_text}</th>
                                            <td>{e.sub_text}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ borderLeft: '1px solid #000' }} />
                        <div className="nutrition_container__instruction  ">
                            <div className="nutrition_container__nutrition_table">
                                <h4 className='ft-w6 mb-3'>Product Details</h4>
                                <table className="table table_container">
                                    <tbody>
                                        {productDetails.map((e: any) => {
                                            return <tr>
                                                <th>{e.heading_text}</th>
                                                <td>{e.text}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}