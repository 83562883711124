import Breadcrumb from "react-bootstrap/Breadcrumb";

function SiteMap() {
  return (
    <div className="banner_container">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      </Breadcrumb>
      <br />

      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://www.vrishkarpalmproducts.com/products/">
          Products
        </Breadcrumb.Item>
      </Breadcrumb>
      <br />
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://www.vrishkarpalmproducts.com/products/palm-jaggery/">
         Palm Jaggery
        </Breadcrumb.Item>
      </Breadcrumb>
      <br />

      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://www.vrishkarpalmproducts.com/products/palm-jaggery-Liquid/">
         Palm Jaggery Liquid
        </Breadcrumb.Item>
      </Breadcrumb>
 <br />

 <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://www.vrishkarpalmproducts.com/nuterition">
          Products - Palm Jaggery Nutrition Information
        </Breadcrumb.Item>
      </Breadcrumb>
 <br />
 

 <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://www.vrishkarpalmproducts.com/blog/Benefits-Of-Palm-Jaggery">
        Products - Palm Jaggery blog
        </Breadcrumb.Item>
      </Breadcrumb>
 <br />

 <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://www.vrishkarpalmproducts.com/FAQS">
        Products - FAQ
        </Breadcrumb.Item>
      </Breadcrumb>
 <br />
      
 <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://www.vrishkarpalmproducts.com/404-NotFound">
        Products - Not Found
        </Breadcrumb.Item>
      </Breadcrumb>
 <br />
      
    </div>
  );
}

export default SiteMap;
