import React from "react";
import "./PrivacyPolicy.scss";
export const PrivacyPolicy = () => {
  return (
    <div className="privacy_policy_container">
      <h2 className="text-center ft-w6">Privacy policy</h2>
      <p>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit or make a purchase from THE
        TRIBE CONCEPTS (the “Site”).
      </p>
      <h3>PERSONAL INFORMATION WE COLLECT</h3>
      <p className="">
        When you visit the Site, we automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies that are installed on your
        device. Additionally, as you browse the Site, we collect information
        about the individual web pages or products that you view, what websites
        or search terms referred you to the Site, and information about how you
        interact with the Site. We refer to this automatically-collected
        information as “Device Information.”
      </p>
      <h3>We collect Device Information using the following technologies: </h3>
      <p>
        -“Cookies” are data files that are placed on your device or computer and
        often include an anonymous unique identifier. For more information about
        cookies, and how to disable cookies, visit
        http://www.allaboutcookies.org.
      </p>
      <p>
        - “Log files” track actions occurring on the Site, and collect data
        including your IP address, browser type, Internet service provider,
        referring/exit pages, and date/time stamps.
      </p>
      <p>
        - “Web beacons,” “tags,” and “pixels” are electronic files used to
        record information about how you browse the Site.
      </p>
      <p>
        Additionally when you make a purchase or attempt to make a purchase
        through the Site, we collect certain infor mation from you, including
        your name, billing address, shipping address, payment information
        (including credit card numbers, email address, and phone number. We
        refer to this information as “Order Information.” When we talk about
        “Personal Information” in this Privacy Policy, we are talking both about
        Device Information and Order Information.
      </p>
      <h3>HOW DO WE USE YOUR PERSONAL INFORMATION? </h3>
      <p>
        We use the Order Information that we collect generally to fulfill any
        orders placed through the Site (including processing your payment
        information, arranging for shipping, and providing you with invoices
        and/or order confirmations).
      </p>
      <p>
        Additionally, we use this Order Information to: Communicate with you;
        Screen our orders for potential risk or fraud; and When in line with the
        preferences you have shared with us, provide you with information or
        advertising relating to our products or services.
      </p>
      <p>
        We use the Device Information that we collect to help us screen for
        potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our Site (for example, by generating
        analytics about how our customers browse and interact with the Site, and
        to assess the success of our marketing and advertising campaigns).
      </p>
      <h3>SHARING YOUR PERSONAL INFORMATION</h3>
      <p>
        We may also share your Personal Information to comply with applicable
        laws and regulations, to respond to a subpoena, search warrant or other
        lawful request for information we receive, or to otherwise protect our
        rights.
      </p>
      <h3> BEHAVIOURAL ADVERTISING</h3>
      <p>
        As described above, we use your Personal Information to provide you with
        targeted advertisements or marketing communications we believe may be of
        interest to you. For more information about how targeted advertising
        works, you can visit the Network Advertising Initiative’s (“NAI”)
        educational page at
        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.{" "}
      </p>
      <p>
        You can opt out of targeted advertising by: [[ INCLUDE OPT-OUT LINKS
        FROM WHICHEVER SERVICES BEING USED. COMMON LINKS INCLUDE: FACEBOOK -
        https://www.facebook.com/settings/?tab=ads GOOGLE -
        https://www.google.com/s ettings/ads/anonymous BING -
        https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
        ]]
      </p>
      <p>
        Additionally, you can opt out of some of these services by visiting the
        Digital Advertising Alliance’s opt-out portal at:
        http://optout.aboutads.info/.
      </p>{" "}

      <h3>DO NOT TRACK</h3> 
      <p>Please note that we do not alter our Site’s data collection
      and use practices when we see a Do Not Track signal from your browser.
      </p>
      <h3>DATA RETENTION</h3> 
      <p>When you place an order through the Site, we will maintain
      your Order Information for our records unless and until you ask us to
      delete this information.</p>
      <h3>Encrypted Transmission</h3> At
      www.thetribeconcepts.com we use the industry’s highest standard of data
      encryption, and positive identification technology to ensure that your
      online shopping experience is safe and secure. Security measures are
      triggered automatically with your order. Your order will be submitted only
      if your computer and our server are communicating in a secure mode.
      Security measures are employed throughout our Website, wherever you’ve
      been asked to submit information. We employ the industry’s most stringent
      security technology to encrypt sensitive information, including your
      contact information and credit card number. Our Products The products
      portrayed and described on this website are all handmade and therefore are
      in their unique formulation from one another regardless of whether they
      are from the same production batch or not. Purchase Related Policies All
      our products portrayed and described on this website and any samples
      and/or gifts that we may give, offer, or provide to you are for personal
      use only and not permitted for sale or resale by you or by any individual
      without our consent. With or without any notice, we reserve all rights to
      cancel or reduce the volume of products/services to be
      given/provided/offered to you if we believe that our Terms & Conditions
      may be violated. Accuracy of Information We attempt to be as accurate as
      possible when describing our products on the website; however, to the
      extent permitted by applicable law, we do not warrant that the product
      descriptions, colors or other content available on the website are
      accurate, complete, reliable, current, or error-free. Limitation of
      Liability Except at the extent where warranties are legally not
      excludable, THE TRIBE CONCEPTS makes no representations or warranties of
      any form. There is a possibility that certain individuals are allergic to
      certain ingredients by nature or due to pre-existing medical conditions.
      Hence, it is highly important that one reads through the listed
      ingredients under every product to ensure that these are safe for one’s
      use and condition. It is the sole responsibility of the purchaser and user
      to take the needed precautions and safety measures to avoid possible
      health conditions before the use of our products that may not suit his/her
      skin. We are not responsible or liable for any allergic reactions as
      mentioned above. The website is presented “as is.” we make no
      representations or warranties of any kind whatsoever, express or implied,
      in connection with these terms and conditions or the website, including
      but not limited to warranties of merchantability, non-infringement or
      fitness for a particular purpose, except to the extent such
      representations and warranties are not legally excludable. We are not
      responsible for timeliness, accuracy, unavailability or interruptions in
      availability, viruses or other defects in the website or its contents. In
      no event will we be liable for any direct, indirect, special, incidental,
      or consequential damages of any kind (including lost profits) related to
      the site regardless of the form of action whether in contract, tort or
      otherwise, even if we have been advised of the possibility of such
      damages. Intellectual Property Rights All rights are reserved by us. All
      our content such as our logo, texts, images, graphical designs, videos,
      animations, layout, and models are all subject to intellectual property
      protection acts. None of the mentioned content may be modified or copied
      for any purpose without our consent. Disputes With respect to any dispute
      regarding the website, all rights and obligations and all actions
      contemplated by these Terms and Conditions shall be governed by the laws
      of India as if the Terms and Conditions were a contract wholly entered
      into and wholly performed within the jurisdiction. Any disputes that may
      arise with regards to this website, or actions breaching its terms and
      conditions, shall be heard by a sole arbitrator appointed by the Founder
      of THE TRIBE CONCEPTS in accordance with the law. CHANGES We may update
      this privacy policy from time to time in order to reflect, for example,
      changes to our practices or for other operational, legal or regulatory
      reasons. CONTACT US For more information about our privacy practices, if
      you have questions, or if you would like to make a complaint, please
      contact us by e-mail at support@thetribeconcepts.com.
    </div>
  );
};
