export const envUrl = 'http://localhost:3009'
export const envDevUrl =
  'https://t1gzkkylyi.execute-api.us-east-2.amazonaws.com/dev'
export const getAddressUrl = 'https://api.getaddress.io/find'

export const jobs = [
  'All Jobs',
  'Completed Jobs',
  'Today Jobs',
  'Up Coming Jobs',
]

export const Faqs = [
  {
    id:1,
    question:"What is palm jaggery?",
    answer:`Palm jaggery also known as "karupatti" ,” palmyra jaggery”, “patali gur”, “khejur gur”, “nolen gur” and karupatti. in some regions is a natural sweetener that is made from the sap of palm trees. It is a traditional sweetener that is widely used in South Asian and Southeast Asian cuisines. Palm jaggery is made by boiling the sap from the palm trees until it thickens and solidifies into a block or cake. Unlike refined sugar, palm jaggery retains its natural vitamins and nutrients`
  },
  {
    id:2,
    question:"Which is the healthiest jaggery?",
    answer:`
    All types of jaggery are considered healthier than refined sugar because they are less processed and retain some of the natural nutrients found in sugarcane. However, some types of jaggery are considered healthier than others. Here are a few examples:
   \n
    1. Date palm jaggery: This type of jaggery is considered to be the healthiest because it is rich in minerals like iron, calcium, and potassium.
   \n
    2. Coconut jaggery: This type of jaggery is also`
    
  },
  {
    id:3,
    question:"Can diabetics eat palm jaggery?",
    answer:"Date Palm jaggery is known to have a lower glycemic index compared to regular sugar, which means it causes a slower and gradual rise in blood sugar levels. However, it still contains carbohydrates and calories, so it should be consumed in moderation by people with diabetes. Diabetics should consult their doctor or a registered dietitian before consuming palm jaggery or any other sweetener to ensure it fits into their overall meal plan and blood sugar management goals"
  },
  {
    id:4,
    question:"Is palm jaggery good for health?",
    answer:"Yes, Date palm jaggery is considered good for health as it is a natural sweetener that is rich in vitamins and minerals. It is made from the sap of palm trees and is a good source of iron, calcium, and potassium. It is also believed to have a low glycemic index, which means it does not cause a sudden spike in blood sugar levels. Additionally, it is known to have antioxidant properties and can help boost the immune system."
  },
  {
    id:5,
    question:"What is the difference between jaggery and palm jaggery?",
    answer:`
    Jaggery is a traditional non-centrifugal cane sugar consumed in many countries in Asia and Africa. It is made by boiling sugarcane juice until it solidifies and is then molded into blocks.
   \n
    Date Palm jaggery, on the other hand, is made from the sap of palm trees, such as date palms and coconut palms. The sap is collected and boiled until it solidifies into a dark brown, caramel-like substance. Palm jaggery has a distinct flavor and aroma compared`
    
  },
  {
    id:6,
    question:"When should I eat palm jaggery?",
    answer:`
    Date Palm jaggery can be consumed at any time of the day, but it is recommended to consume it in moderation. It can be used as a natural sweetener in various dishes such as desserts, smoothies, and tea. However, it is important to keep in mind that palm jaggery is still a form of sugar and should be consumed in limited quantities. It is also recommended to consult with a healthcare professional if you have any specific health concerns or conditions before adding palm jaggery`

  },
  {
    id:7,
    question:"Does palm jaggery contain sugar?",
    answer: `
    Yes,Date palm jaggery does contain sugar, but it is a healthier alternative to refined sugar. Palm jaggery is made from the sap of palm trees and is a natural sweetener that is rich in vitamins and minerals. It contains sucrose, glucose, and fructose, which are all forms of sugar. However, unlike refined sugar, palm jaggery is not processed and does not contain any harmful chemicals or additives. It also has a lower glycemic index than refined sugar, which is really good for health.`
  },
  {
    id:8,
    question:"Is palm jaggery anti inflammatory?",
    answer:"Yes, Date palm jaggery has anti-inflammatory properties. It contains antioxidants and phytochemicals that help to reduce inflammation in the body. In Ayurveda, palm jaggery is often used as a natural remedy for various inflammatory conditions such as arthritis, joint pain, and other inflammatory disorders. Additionally, palm jaggery is also known to boost the immune system and improve digestion, which can further help to reduce inflammation."
  },
  {
    id:9,
    question:"What is the difference between brown sugar and palm jaggery?",
    answer: `
    Brown sugar is made by adding molasses back into refined white sugar, which gives it a brown color and a slightly caramel flavor. Date Palm jaggery, on the other hand, is made by boiling the sap of palm trees until it thickens and solidifies into a block. 
   \n
    Date Palm jaggery is considered to be a healthier alternative to brown sugar as it is unrefined and contains more minerals and nutrients, such as iron, calcium, and potassium. It also has a lower GI.`
  },
  {
    id:10,
    question:"What is the other name of date palm?",
    answer:`
    Date Palm is also known as 
    Indian wild date palm\n
    Telugu: ఈత ita ,<br/>
    date sugar palm,\n
    Indian wild date,\n
    Indian wine palm,\n 
    silver date palm,\n 
    sugar date palm,\n
    sugar palm, wild date,\n
    Bengali: খর্জূর kharjura, খেজুর khejura ,Nelon gur, Patil gur,\n
    Gujarati: ખજૂરી khajuri,\n
    Hindi: खजूर khajur, सेंधी sendhi,\n
    Kannada: ಈಚಲು ichalu, ಖರ್ಜೂರ kharjura,\n
     Malayalam: കാടിനൊത്ത kaattiintha, കാട്ടീന്തല്‍ kaattiinthal, നീലന്തെണ്ട് niilanthent,\n
     Manipuri: থাঙতুপ thangtup,\n
    Marathi: खारीक kharik, खर्जूर kharjur, खर्जूरी kharjuri, शिंद shinda, शिंदी shindi,\n
    Oriya: khorjurri ,\n
    Punjabi: khajur,\n
    Sanskrit: खर्जूरः kharjurh, खर्जूरी kharjuri, नेपाली nepali ,\n
    Tamil: ஈந்துபனை inthupaanai, காட்டீஞ்சு kattinchu,\n
    Urdu: کهجور khajur,\n`
  },
  {
    id:11,
    question:"Top 10 Health Benefits Of Palm Jaggery",
    answer: `
    Relieves common ailments like cold and cough:<br/>
    Date palm jaggery can be used to treat dry cough and cold. It helps to clear the respiratory tract by dissolving the mucus. It is a good home remedy for people suffering from respiratory problems like asthma.
   \n
    Rich in nutrients:\n
    The nutrients present in date palm jaggery play a vital role in keeping your body functioning well, which is exactly what you need during winter. Some of the significant minerals found in it are iron (essential for blood production) and magnesium (helps regulate the nervous system).
    ​Helps in weight loss
    Higher count of potassium in date palm jaggery reduces water retention and bloating which helps promote weight loss.
   \n
    
    ​Boosts energy\n
    If you are feeling low on energy on a cold winter day, try eating some date palm jaggery. It is high in composite carbohydrates which help in digesting food faster than the regular sugar. Consuming a piece of this jaggery regularly helps in releasing energy which keeps you energetic and active for many hours.
   \n
    
    Treats menstrual pain<br/>
    Date palm jaggery is an effective natural remedy for treating menstrual issues. Its consumption releases endorphins, which calm your body and relieves cramps and stomach aches.
    Restores healthy digestion
    Winter can bring in all kinds of stomach problems, either from sickness or spicy food, which we often crave for in the season. Date palm jaggery acts as a digestive agent and helps to alleviate stomach pain. It activates digestive enzymes inside your stomach and helps clean the intestinal tracts.<br/>
   \n
    Eases joint pain<br/>
    Date palm jaggery strengthens the bones, thereby reducing joint pain and other related problems like arthritis. It is rich in calcium thus making the bones stronger and healthier.
   \n
    Ultimate cleanser<br/>
    
    Palm jaggery cleans up your entire body system by flushing out all the toxins from the body and keep your body healthy. It also helps in cleansing the respiratory tract, intestines, food pipe, lungs and stomach.`
    
  },
  {
    id:12,
    question:"What can palm jaggery be used for?",
    answer:"Date Palm jaggery can be used as a natural sweetener in various dishes and beverages. It is commonly used in Indian cuisine to sweeten desserts, such as payasam and laddus. It can also be used in tea or coffee as a healthier alternative to refined sugar. Additionally, it can be used in marinades and sauces to add a sweet and savory flavor to dishes."
  },
  {
    id:13,
    question:"Can we eat palm jaggery daily?",
    answer:"Date Palm jaggery can be consumed daily in moderation as a healthier alternative to refined sugar. It is a rich source of iron, calcium, and other minerals, and has a lower glycemic index than white sugar, which means it does not cause a sudden spike in blood sugar levels. However, like any other sweetener."
  },
  {
    id:14,
    question:"Is palm jaggery good for your hair?",
    answer:"Yes, Date palm jaggery is good for your hair. It contains essential vitamins and minerals such as iron, magnesium, and potassium, which are beneficial for hair growth and health. Palm jaggery also helps to strengthen hair follicles and prevent hair fall. It can be used as a natural hair conditioner by mixing it with coconut oil or other hair oils. The mixture can be applied to the hair and scalp and left for a few hours before washing off. This can help to nourish the hair`"
  },
  {
    id:15,
    question:"Is palm jaggery healthier than sugar?",
    answer:"Date Palm jaggery is considered healthier than sugar because it is less processed and contains more nutrients than regular sugar. Palm jaggery is made from the sap of palm trees and is rich in minerals like iron, magnesium, and potassium. It also has a lower glycemic index than sugar, which means it causes a slower rise in blood sugar levels. However, like any sweetener, it should be consumed in moderation as it still contains calories."
  },
  {
    id:16,
    question:"Which is better: honey or jaggery?",
    answer:`
    Both honey and palm jaggery have their own unique health benefits and can be used as natural sweeteners. Honey is rich in antioxidants and has antibacterial properties, while palm jaggery is rich in minerals like iron, magnesium, and potassium.
   \n
    
    In terms of sweetness, honey is sweeter than palm jaggery, so you may need to use less of it to achieve the same level of sweetness. However, honey has a higher glycemic index than palm jaggery, These days its highly unlikely  to get pure  honey.  `
  },
  {
    id:17,
    question:"Date Palm Jaggery benefits for females?",
    answer:"Date palm jaggery is an effective natural remedy for treating menstrual issues. Its consumption releases endorphins, which calm your body and relieves cramps and stomach aches."
  },
  {
    id:18,
    question:"Is palm jaggery good for kids?",
    answer:"Palm jaggery can be a good alternative to refined sugar for kids as it is less processed and contains more nutrients. It is a good source of iron, which is important for children's growth and development. However, like any sweetener, it should be consumed in moderation as it still contains calories. It is recommended to consult with a pediatrician before introducing any new food or sweetener to a child's diet"
  },
  {
    id:19,
    question:"Does date palm jaggery help in women periods?",
    answer:"Date palm jaggery is believed to have several health benefits, including the ability to regulate menstrual cycles. It is also rich in iron, which can help alleviate symptoms of anemia that can occur during menstruation"
  },
  {
    id:20,
    question:"Which is better for babies? palm sugar or Date Palm jaggery?",
    answer:"Both palm sugar and date palm jaggery are natural sweeteners that can be used for babies. However, date palm jaggery is considered to be a healthier option as it is rich in vitamins and minerals such as iron, calcium, and potassium. It is also believed to have several health benefits, including boosting immunity and aiding digestion. On the other hand, palm sugar is also a good source of energy and is considered to be a better option than refined sugar. Ultimately, it is best to"
  },
  {
    id:21,
    question:"Does date palm jaggery expire?",
    answer:"any other food item, date palm jaggery also has an expiration date. The shelf life of date palm jaggery depends on how it is stored. If stored in a cool and dry place, it can last for up to six months to a year. However, if it is exposed to moisture or stored in a humid environment, it can spoil quickly and develop mold or fungus. It is always best to check the expiration date on the packaging and consume it before the expiry date"
  },
  {
    id:22,
    question:"Do our Palm Products are chemical free?",
    answer:"Our Products are unrefined and non-distilled, so no chemicals are used in making it. So all the nutrients like magnesium, iron, calcium, and phosphorus are retained.No artificial flavours. No preservatives. No added sugar."
  }
]