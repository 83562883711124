import './ReturnAndRefundPolicy.scss'
export const ReturnAndRefundPolicy = () => {
    return (
        <div className="returrn_refund_policy">
            <h2 className="text-center ft-w6">Return and Refund Policy</h2>
            <h4 className="mt-5 ft-w6 ">Return & Replacement Policy for Domestic Orders</h4>
            <p
            // className="fs_16 ft-w6"
            >
                The Products purchased from ‘The Tribe Concepts’
                are subject to return and replacement or exchange
                as per the Return & Refund Policy within the
                applicable return or replacement period if you’ve
                received them in a condition that is physically
                damaged or different from their description on
                the product detail. Return or replacement will
                be processed if you write to us within 48 hours
                of receiving the product and only if the following
                conditions are met:
            </p>
            <p>
                1.1 It  is agreed that the product is damaged while
                in transit or during the delivery process.
            </p>
            <p>
                1.2 The product received by you is evidentially
                different from what had been confirmed by you while
                placing the order;
            </p>
            <p>
                1.3 The product is returned in its original condition
                (with brand’s/manufacturer's box, original sales receipt,
                and all the accessories therein).
            </p>
            <p>
                In the event that any of
                the above conditions are fulfilled, we will replace your
                ordered products or initiate a refund, provided that you
                agree to the following:
            </p>
            <p>
                1.4 You agree to send an Email  from your
                registered Email ID to us in support@thetribeconcepts.com
                along with the Invoice Number highlighting the following:
            </p>
            <p>
                1.4.1 Clear images and videos of the damaged products,
                leakage or spillage if any, mono-carton and outer packaging
                images. Please note that the video must clearly showcase the
                unboxing of the package in order to evidence the damaged products,
                leakage or spillage.
            </p>
            <p>
                1.4.2 In the event of any product missing in the package or
                a wrong product being delivered or issue with the weight of
                the product, you agree to share with us clear images and video
                clippings taken while unboxing the package and of the open
                package along with the products received for our understanding and confirmation.
            </p>
            <p>
                1.4.3 Once, the aforementioned details are received,
                you agree to provide us at least 5 working days time
                to look into your complaint and revert back to you with
                a resolution. If on the basis of the evidence shared by you,
                we determine to approve your order return/exchange request,
                you will be duly informed of the same in writing and a reverse
                pick up of the product will be arranged from our end.
            </p>
            <p>
                1.4.4 All orders products which have been approved to be
                exchanged from our end will need to be returned in the
                samecondition and packaging as it was received and handed
                over to our pick up Logistic Service Provider.Notwithstanding
                the above, this shall not apply in an instance where there has
                been visible damage or breakage caused to the product containers
                during the transit and the same shall be replaced by us without
                any cost. Provided that, you agree to share the images and videos
                of the damaged products while unboxing for our record and confirmation
                for replacement.
            </p>
            <p>
                1.4.5 Once the return package is received by our warehouse
                team, we will conduct a quality check of the product and also
                validate the product weight.  The exchange or replacement of
                the order will be processed within 48-72 hours once the quality
                and weight check is completed and you will be provided with the
                new consignment tracking details vide an Email on your registered
                Email Id.
            </p>
            <p> 1.5 Note: Do note that any exchange or replacement refund request with respect to our Gifting collection will not be applicable for exchange or return unless the said Gift Boxes have been physically damaged at the time of delivery and necessary evidences in terms of images and videos have been furnished within
                24  hours of receipt of such damaged Gift Boxes.
            </p>
            <h4 className='ft-w6 mt-2'>Refund Policy for Domestic Orders</h4>
            <p>
                2.1 In the instance of product refund being approved, the ordered products will be picked up from you by our pick up Logistic Service Provider. Once the  same is received by our warehouse team, a quality and weight check of the product will be conducted and basis    the approval received, refund will be initiated within 48-72 business hours of the return. The refund amount once processed will reflect in your account within 10-15 working days.
            </p>
            <p>  2.2 Any payment made by you vide Cash on Delivery mode shall be refunded once you share your Bank Account details with us from your registered Email ID. The refund amount once processed, will reflect in your account within 10-15 working days. Provided that
                apart from the said mode of refund, no other form of refund in terms of UPI payment ( like Google Pay, Phone pe, Paytm and similar wallet payment mode) shall not be encouraged or accepted.
            </p>
            <h4 className='ft-w6 mt-2' >Refund Policy for International Orders</h4>
            <h3 className='fs_16'>Refund for international orders will be processed if you write to us within
                48 hours of receiving the product and only if the following conditions
                are met:
            </h3>
            <p>
                3.1 The product received is damaged while in transit or during the delivery process. In such an event, you agree to share with us clear images and videos of the damaged products, leakage or spillage if any, mono-carton and outer packaging images. Please note that the video must clearly showcase the unboxing of the package in order to evidence the damaged products, leakage or spillage.
            </p>
            <p>
                3.2 The product received by you is evidentially different from what was confirmed by you while placing the order.
            </p>
            <p>            3.3 In the event of any product missing in the package or a wrong product being delivered or issue with the weight of the product, you agree to share with us clear images and video clippings taken while unboxing the package and of the open package along with the products received for our understanding and confirmation.
            </p>
            <p>            3.4 Once, the aforementioned details are received, you agree to provide us at least 5 working days time to look into your complaint and revert back to you with our confirmation either on refund or compensation in the form of a special ‘discount coupon’ based on case to case basis.  If on the basis of the evidence shared by you, we determine to approve your refund or or compensation in the form of a special ‘discount coupon’ based on case to case basis, the same will be initiated within 48-72 business hours and the compensation as mentioned above will be processed within 5-7 working days and you shall be duly notified of the same on your registered Email ID.
            </p>
            <p>            3.5 Note:
            </p>
            <p>            Any refund request with respect to our Gifting collection will not be applicable unless the said Gift Boxes have been physically damaged at
                the time of delivery and necessary evidence in terms of images and videos have been furnished within 24 hours of receipt of such damaged
                Gift Boxes.
            </p>
            <h4 className="ft-w6">
                Please note that any request for replacement,
                exchange or refund the above shall not apply
                under the following conditions:
            </h4>
            <p>            4.1 Any request for replacement, exchange, return or refund of order due to any individual allergy or skin reactions  is not applicable as the
                products are lab tested and certified Ayurvedic products.
            </p>
            <p>            4.2 Replacement or Exchange of product due to reasons including personal dislike for the product; dislike of the fragrance; dislike of color,
                consistency or texture, or any personalised opinion about the product cannot be approved for exchange, refund, return.
            </p>
            <p>            Judge.me Verified Reviews Badge219Verified Reviews
            </p>
        </div >
    )
}