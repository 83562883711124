import './CheckoutPage.scss'
import cart_choco_img from '../../assets/images/cart-choco-img.png'
import delete_icon from '../../assets/images/delete_icon.svg'
import logos_stripe from '../../assets/images/logos_stripe.svg'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const CheckoutPage = () => {
    const navigator = useNavigate()

    const product: any = localStorage.getItem("products") !== null ?
        JSON.parse(`${localStorage.getItem("products")}`) : []

    const [products, setProducts] = useState<any>([])
    const prod = products.reduce((accumulator: any, object: any) => {
        return accumulator + (object.Price * object.Quantity);
    }, 0);

    const increamentCount = (data: any) => {
        const { ProductId } = data
        const cartData = products.map((item: any) =>

            ProductId === item.ProductId ? { ...item, Quantity: item.Quantity + 1 } : item
        )
        localStorage.setItem('products', JSON.stringify(cartData))
    }

    const decreamentCount = (data: any) => {
        const { ProductId } = data
        const cartData = products.map((item: any) => {
            if (item.Quantity >= 1) {
                return ProductId === item.ProductId ? { ...item, Quantity: item.Quantity - 1 } : item
            } else {
                return ProductId === item.ProductId && item.Quantity >= 0 ? null : item
            }
        }
        )
        localStorage.setItem('products', JSON.stringify(cartData))
    }

    useEffect(() => {
        setProducts(product)
    }, [])

    return (
        <div className='checkout_content'>
            <h1 className='cart_item_content'>Your Basket</h1>
            <div className='d-flex justify-content-between cart_item_content mb-screen'>
                <div>
                    <div className="card p-2 cart_product_container">
                        {products?.map((card: any, indx: any) => {

                            return <>
                                <hr style={{ display: indx === 0 ? 'none' : 'block' }} />
                                <div className='d-flex'>
                                    <img src={cart_choco_img} alt='' className='px-2 cart_choco_img' />
                                    <div className='d-flex justify-content-between'
                                        style={{ flex: '1 30%' }} >
                                        <div>
                                            <h6>{card.productName}</h6>
                                            <p>Vrishkar's Palm Products</p>
                                        </div>
                                        <div>
                                            <div className='d-flex' >
                                                <div className='increment_decrement d-flex justify-content-between align-items-center'>
                                                    <div className='ft-w1'>
                                                        <span onClick={() =>
                                                            increamentCount(card)
                                                        }>+</span>
                                                    </div> {card.Quantity} <div>
                                                        <span onClick={() =>
                                                            // setQuantity((pre: any) => pre - 1)
                                                            decreamentCount(card)
                                                        }>-</span>
                                                    </div>
                                                </div>
                                                <img src={delete_icon} alt='' className='delete_icon' onClick={() => {
                                                    const data: any = localStorage.getItem("products");
                                                    const value: any = JSON.parse(data)
                                                    localStorage.setItem("products", JSON.stringify([...value.filter((e: any) => e.ProductId !== card.ProductId)]))

                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        )}
                    </div>
                </div>
                <div className='vertical_line'></div>
                <div>
                    <div className='card cart_product_container order_summary'>
                        <h5 className='mt-3'>Order summary</h5>
                        <div className='d-flex justify-content-between'>
                            <p className='fs_14 ft-w6'>Subtotal</p>
                            <p className='fs_14 ft-w6'>
                                {/* £19.99 */}
                                {/* {console.log("products",dfdf)} */}
                                {prod}
                            </p>
                        </div>
                        {/* <div className='d-flex justify-content-between'>
                            <p className='fs_14 ft-w6'>Savings</p>
                            <p className='fs_14 ft-w6'>-£0.00</p>
                        </div> */}
                        <div className='d-flex justify-content-between'>
                            <p className='fs_14 ft-w6'>Default Delivery</p>
                            <p>£3.49</p>
                        </div>

                        <p className='fs_10 mt-4 ft-w6'>Add Voucher code</p>
                        <div className='d-flex justify-content-between'>
                            <p className='fs_14 ft-w6'>Total (1 item)</p>
                            <p className='fs_14 ft-w6'>Total £{prod + 3.49}</p>
                        </div>

                        <button className='btn primary_color ft-6' onClick={() => {
                            navigator('/signup')
                        }}>Checkout</button>
                        <div className='fs_12 text-center mt-3'>
                            By proceeding with this payment you agree
                            to <span className='primary_color_text ft-w7'>vk terms and conditions</span>
                        </div>
                        <div className='d-flex justify-content-around align-items-center mt-4 mb-3 mx-2'>
                            <img src={logos_stripe} width={50} alt='' />
                            <div className='fs_8'>A complete payments platform, engineered for growth</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}