import bsnnerLeftImg from '../../assets/images/banner_left.png'
import banner_chocolate_img from '../../assets/images/banner_chocolate_img.jpeg'
import child_banner_img from '../../assets/images/child_banner_img.png'
import leafe_icon from '../../assets/images/leafe_icon.svg'
import palm_leafe_with_circle from '../../assets/images/palm_leafe_with_circle.png'
import palm_box_icon from '../../assets/images/palm_box_icon.svg'
import gg_math_equal from '../../assets/images/gg_math-equal.png'
import palm_boiler from '../../assets/images/palm_boiler.png'
import akar_icons_plus from '../../assets/images/akar-icons_plus.png'
import disable_icon from '../../assets/images/disable_icon.png'
import doctor_img from '../../assets/images/doctor_img.svg'
import circle_img from '../../assets/images/why_choose_circle_img.png'
import the_natural_taste_of_india from '../../assets/images/the_natural_taste_of_india.png'
import { experts_says, palm_product_constant, why_choose } from '../../constants'
import './LandingPage.scss'
import mobile_banner_img from '../../assets/images/mobile_bannerr_img.png'
import mobi_text_img from '../../assets/images/mobi_text_img.png'
import { useNavigate } from "react-router-dom";

export const LandingPage = () => {
    const history = useNavigate();
    const  redirectToShopNow = () =>  {
        history("/products");
      }
    return (
        <div className='banner_container'>
            <div className='banner_container__content '>
                <div className='content_flex image_content_btn col-md-6 col-12 mob_content'>
                    <img src={bsnnerLeftImg} alt='' className='banner_container__content__left_img' />
                    <div className='btn_container'>
                        <button onClick={redirectToShopNow} className='btn' >Shop Now</button>
                    </div>
                </div>
                <div className='content_flex image_content_btn col-md-6 desktop_content'>
                    <img src={mobile_banner_img} alt='' className='img_responsive' />
                </div>
                <div className='col-md-6 banner_container_img'>
                    <div className='banner_container_img_im'>
                        <img src={banner_chocolate_img} alt=""
                            className='banner_container__content__right_img' />
                    </div>
                </div>
                <div className='btn_container desktop_content text-center my-3'>
                    <img src={mobi_text_img} alt='' className='img_responsive' />
                </div>
                <div className='image_content_btn desktop_content'>
                    <button className='btn' onClick={redirectToShopNow}>Shop Now</button>
                </div>
            </div>
            <div>
                <h1 className='ft-w8 fa-c mt-4 natural_palm_heading'>Offering Natural Palm Jaggery</h1>
                <p className='fa-c fs_15 ft-w6 offering_natural_palm' >
                    Its deliciously Rich in dark colour. Vrishkar Indian wild date
                    sap is gently boiled to give deliciously sweet palm chikki, Palm JaggeryPalm Jaggery Liquid or Syrup and Palm Jaggery Biscuits or Palm Jaggery Cookies
                </p>
                <div className='content_flex mb-screen justify_content_sa content_items_c offering_natural_palm'>
                    <div className='image_child_img_content'>
                        <img src={child_banner_img} alt='' />
                    </div>
                    <div>
                        {palm_product_constant.map((e: any, index: any) => {
                            return (
                                <div className='content_flex palm_text_content content_item_fs content_items'>
                                    <img className='palm_text_content__image_container leafe_img' src={leafe_icon} alt='' />
                                    <p className='fs_18 ft-w5'>{e.text}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div>
                <h1 className='ft-w8 fa-c mt-4'>How it Works?</h1>
                <p className='fa-c fs_18 ft-w6'>From the Indian Wild Date Palm trees - Direact to Your homes</p>
                <div className='content_flex mb-screen justify_content_sa content_items align-items-center secondary_color py-4'>
                    <div className='how_it_work'>
                        <img src={palm_leafe_with_circle} alt='' className='circle_with_icon' />
                        <div className='fs_18 how_it_work_text ft-w6 mt-2'>Collect SAP from Palm Tree</div>
                    </div>
                    <div className='how_it_work'>
                        <img src={akar_icons_plus} alt='' className='mob_content' />
                    </div>
                    <div className='how_it_work'>
                        <img src={palm_boiler} alt='' className='circle_with_icon' />
                        <div className='fs_18 how_it_work_text ft-w6 mt-2'>
                            The fresh sap is boiled to make Palm Jaggery, Palm Jaggery Liquid or Syrup, Palm Jaggery Chikki,
                            and Palm Jaggery Biscuits or Cookies</div>
                    </div>
                    <div className='how_it_work'>
                        <img src={gg_math_equal} alt='' className='mob_content' />
                    </div>
                    <div className='how_it_work'>
                        <img src={palm_box_icon} alt='' className='circle_with_icon' />
                        <p className='fs_18 how_it_work_text ft-w6 mt-2'>Pack it</p>
                    </div>
                </div>
            </div>
            <div >
                <h1 className='ft-w8 fa-c mt-4 mb-4' >Why choose us?</h1>
                <div className='content_flex content_items justify_content_sa mb-screen why_choose_us'>
                    <div>
                        <img src={disable_icon} alt='' className='disable_icon' /> <span className='fs_20 ft-w6'>No artificial sweetners</span>
                    </div>
                    <div>
                        <img src={disable_icon} alt='' className='disable_icon' /> <span className='fs_20 ft-w6'>No artificial coloring</span>
                    </div>
                    <div>
                        <img src={disable_icon} alt='' className='disable_icon' /> <span className='fs_20 ft-w6'>No artificial preservatives</span>
                    </div>
                </div>
                <div className='text-center'>
                    <img className='why_choose_content__image desktop_content ' src={circle_img} alt='' />
                </div>
                <div className='mt-4 why_choose_content content-flex mb-screen align-items-center justify-content-around' >
                    <div className=''>
                        {why_choose.map((e: any, index: any) => <div className='content_flex content_item_fs'>
                            <img className='leafe_img' src={leafe_icon} alt="" />
                            <div>
                                <p className='ft-w6'>{e.heading} </p>
                                <p>{e.sub_heading}</p>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className='btn_container desktop_content text-center my-3'>
                        <img className='why_choose_content__image mob_content ' src={circle_img} alt='' />
                    </div>

                </div>


                <div className='secondary_color p-4 experts_says '>
                    <h1 className='ft-w8 fa-c mt-4 mb-4'>Top 10 Health Benefits Of Palm Jaggery</h1>

                    <div className='content_flex content-flex align-items-center mb-screen py-4'>
                        <img className='experts_says__img' src={doctor_img} alt="" />
                        <div>
                     
                            {experts_says.map((e: any, index: any) =>
                                <div className='content_flex content_item_fs'>
                                    <img className='leafe_img mx-2' src={leafe_icon} alt='' />
                                    <div>
                                <p className='ft-w6'>{e.header} </p>
                                <p>{e.text}</p>
                            </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='the-tast_of_india fa-c '>
                    <img src={the_natural_taste_of_india} alt='' />
                </div>
            </div>
        </div>
    )
}


