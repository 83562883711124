import './ShippingPolicy.scss'
export const ShippingPolicy = () => {
    return (
        <div className="shipping_policy_container">
            <h2 className="text-center ft-w6" >Shipping policy</h2>

            <h4 className="ft-w6" >Shipping & Delivery</h4>

            <p className='fs_16'>
                1. All orders placed are shipped from our warehouse in Anantapur Andhra Pradesh
            </p>

            <p className='fs_16'>
                2. Orders can be placed on our website <a href='https://vrishkarpalmproducts'>https://vrishkarpalmproducts/</a> anytime,
                however our operational hours are between 1000 to 1900 hours from Monday to Friday
            </p>

            <p className='fs_16'>
                3. All orders received, will be prepared for dispatch
                and shall be dispatched within 2-3 working days of
                placement of order with our preferred carriers,
                unless it is delayed due to some force majeure events
                or due to delay caused by our logistic or carrier
                partners without our knowledge.
            </p>

            <p className='fs_16'>4. Our normal average delivery
                timeline is between 5-7 working days of dispatch in
                case of domestic shipping and 10-15 working days of
                dispatch in case of international shipping. This shall
                be subject to reasonable delay due to distance, location,
                technical issues in the end of carrier or logistic partner
                and/or other force majeure, or customs clearances in case of
                international orders or due to any extraneous factors beyond
                our control. We would also like to notify that at certain
                instances your ordered products may be subject to delivery
                before the due date because of reasons attributed at
                the end of the carrier or logistic partner.
            </p>

            <p className='fs_16'>
                5. We have a FREE shipping offer for domestic purchases
                done above Rs.1500 and the same shall not be added at
                the time of checkout. Please note that this
                shall be subject to changes as per the Company
                policy and shall only apply to domestic orders.
            </p>

            <p className='fs_16'>
                6. In the event that the order placed by you goes
                amiss or lost during transit, you agree to do the following:
            </p>
            <p className='fs_16 mx-4'>
                6.1 Intimate us in writing within 15 working days from the date of order or
            </p>
            <p className='fs_16 mx-4'>
                6.2  Within 24 hours after receiving any
                communication from the Logistic Service
                Provider (whichever is earlier).
            </p>
            <p className='fs_16 mx-4'>
                6.3  We shall on receipt of such intimation
                within the said timeline take it up with the
                Logistics Service Provider and arrange for
                replacement or initiate refund of the lost order.
                However, we will not be able to guarantee the
                same and shall be on case to case basis. Any
                replacement or refund for your order shall be
                intimated to you vide an Email or SMS.
            </p>
            <p className='fs_16'>
                7. In the event that the tracking status shows delivered,
                you agree to notify us immediately within 2-3 days from
                the date the status of the order being shown as delivered
                on the tracking link/page. Please note that, under this
                scenario the chances to recover the package and deliver
                to you will be favorable only if you intimate us regarding
                the same at the earliest. Provided that, any intimation
                in this regard after the agreed timeline shall not be
                entertained by us as the Logistics Service Provider shall
                not consider the complaint and hence, we will be abstained
                from providing any support to retrieve your order.
                Notwithstanding the above, any order which is lost during
                transit shall be subject to the aforementioned terms and conditions.
            </p>
            <p className='fs_16'>
                8. In the event of any pandemic, epidemic or any force majeure event,
                we will not be able to commit on the delivery timeline. You agree
                to bear with us in the case of any delay in the delivery timeline
                and also cooperate in case of any last minute change in the delivery
                date and time. You will be notified of the same through Email and
                SMS on your registered Email Id and Phone number.
            </p>
            <p className='fs_16'>
                9. Upon delivery of the order, you will receive an SMS confirming acceptance
                of the order from your end. We will not take any responsibility if
                the order has been received and signed off by someone other than our registered customer.
            </p>

            <p className='fs_16'>
                10. We do not take any responsibility for the products damaged
                after the successful delivery and if no written complaint has been
                raised within 48 hours of delivery.
            </p>
            <p className='fs_16'>
                11. We reserve the right to determine the Shipping and handling
                charges as per our discretion and the rates may vary based on
                product, packaging, size, volume, type and other considerations.
                Such charges are given at the time of check out and you will know
                about this before making payments.
            </p>
            <p className='fs_16'>
                12. Please refer to our website terms and conditions for more details.
            </p>
            <p className='fs_16'>
                13. The present policy is final and binding as on date. Any change in the said policy shall be upon the absolute discretion of the Company and the Company reserves the right to make any such changes unilaterally and without any prior notice.
            </p>
        </div >
    )
}