import { nutrition_table_constants, palm_nutrition_constants } from "../../constants"
import leafe_icon from '../../assets/images/leafe_icon.svg';
import the_natural_taste_of_india from '../../assets/images/the_natural_taste_of_india.png';
import Vector from '../../assets/images/Vector.png';
import './NutritionPage.scss'
import React from "react";

export const NuteritionPage = () => {
    return (
        <div className="nutrition_container">
            <h2 className="ft-w5 fa-c">Nutritional facts</h2>
            <p className="fa-c ft-w5 nutrition_container__sub_text fs_16 my-4">“Let Food be thy Medicine means, our food should suffice energy and nutritional needs at the same time”.
                Indian wild date palms are rich source of Vitamins,Minerals, Iron, Magnesium, Calcium and Potassium which
                are necessary for a healthy living and wellbeing</p>

            <div className="content_flex content_items_c mb-screen" >
                <div className="nutrition_container__nutrition_table">
                    <table className="table table_container">
                        <thead>
                            <tr>
                                <th scope="col">Parameters</th>
                                <th scope="col">Nutrition</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {nutrition_table_constants.map((e: any) => {
                                return <tr>
                                    <td >{e.parameter}</td>
                                    <td>{e.nutrition}</td>
                                    <td className="ft-w6">
                                        <img src={Vector} alt='' />
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="nutrition_container__instruction">
                    <div className="secondary_color mx-2 p-3 ">
                        <p><b>Indian wild Date Palm Jaggery benefits : </b> </p>
                        {palm_nutrition_constants.map((e) =>
                            <div className="content_flex content_item_fs">
                                <img src={leafe_icon} alt="" className="leafe_img" />
                                <div>
                                    <p className="ft-w6 fs_18" >{e.header}</p>
                                    <p className="fs_14" >{e.text}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <div className='the-tast_of_india fa-c '>
                <img src={the_natural_taste_of_india} alt='' />
            </div>

        </div>
    )
}