
import { useState } from 'react';
import { useForm } from 'react-hook-form';
// import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'
import { userRegistrationAddressApi } from '../../api/userRegistrationAddressApi';
// import user_register_img from '../assets/images/user_register_img.svg'
import { SignUpForm } from './SignUpForm';
import register_side_img from '../../assets/images/register_side_img.png'
import { emailRegex } from '../../constants/errors';
// import { emailRegex } from '../constants/RegExp';
// import { SignUpForm } from '../forms/SignUpForm'
// import { signUpHelper } from '../helper/signUpHelper';

export const SignUpPage = () => {
    // states
    const [emailError, setEmailError] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(true);

    const [/*confirmPassword*/, setConfirmPassword] = useState(false);
    const [houseNum, setHouseNum] = useState("");
    const [postcodes, setPostCode] = useState("");
    const [apiAddresData, setApiAddresData] = useState([]);
    const [addressApiError, setAddressApiError] = useState(false);
    const [apiPostCode, setApiPostCode] = useState();
    const [showBox, setShowBox] = useState(true);
    const [addSelect, setAddSelect] = useState([]);
    const [boxs, setBoxs] = useState(true);
    const [error, setErros] = useState(false);
    const [addStat, setAddStat] = useState(false);
    const [singleAddCard, setSingleAddCard] = useState(true);
    const [multiAdd, setMultiAdd] = useState(true);
    const [newAddress, setNewAddress] = useState(false);
    const [newAdd, setNewAdd] = useState(false);

    const { handleSubmit, register, formState: { errors } } = useForm({
        mode: 'onChange'
    })
    // const history = useHistory()

    const onChangeHandler = (event: any) => {
        const val = event.target.value;
        if (val.match(emailRegex)) {
            setEmailError(false);
            setEmailSuccess(false);
        } else {
            setEmailSuccess(true);
            setEmailError(true);
        }
    };

    // const signUp = async (data: any) => {
    //     try {
    //         const signUpData = await signUpHelper(data)
    //         if (signUpData) {
    //             history.push({ pathname: '/verifyAccount', state: data })
    //         }
    //     } catch (exception) {
    //         toast.error(`${exception}`)
    //         throw exception
    //     }
    // }

    // Functions
    const Address = async () => {
        try {
            const { data } = await userRegistrationAddressApi(postcodes, houseNum);
            const { addresses, postcode } = data;
            setApiAddresData(addresses);
            setApiPostCode(postcode);
            if (addresses.length > 0) {
                setAddressApiError(false);
                setAddStat(true);
                setShowBox(false);
                if (!boxs) {
                    setBoxs(true);
                }
                if (singleAddCard === false) {
                    setSingleAddCard(true);
                }
                if (multiAdd === false) {
                    setMultiAdd(true);
                }
            } else if (addresses.length === 0) {
                setNewAddress(true);
                setAddressApiError(false);
            } else {
                setAddressApiError(true);
            }
        } catch (exception) {
            setShowBox(true);
            setAddressApiError(true);
            if (exception) {
                setAddressApiError(true);
            }
            console.log(exception);
            throw exception;

        }
    };

    const onSubmit = async (data: any) => {

        try {
            if (data.password !== data.confirmPassword) {
                toast.warning(` Confirm password didn't match to password`);
            }
            else if (!addStat) {
                setErros(true);
                setConfirmPassword(false);
            } else {
                setErros(false);
                setConfirmPassword(false);
                // const signUpData = await signUpHelper(
                //     apiAddresData,
                //     addSelect,
                //     data,
                //     apiPostCode
                // );
                // const signUpData = await signUpHelper(data)
                // if (signUpData) {
                    // ({ pathname: '/verifyAccount', state: data })
                // }
            }
        } catch (exception) {
            toast.error(`${exception}`);
        }
    };

    return (
        <>
            {/* <ToastContainer /> */}
            <div className='singup_page'>
                <div className='singup_page__create_account'>
                    <h1 className='singup_page__heading heading '>User Details</h1>
                    <div className='singup_page__text-field-content'>
                        <SignUpForm
                            signUp={onSubmit}
                            onChangeHandler={onChangeHandler}
                            handleSubmit={handleSubmit}
                            register={register}
                            errors={errors}
                            emailError={emailError}
                            emailSuccess={emailSuccess}
                            addressApiError={addressApiError}
                            showBox={showBox}
                            error={error}
                            newAddress={newAddress}
                            newAdd={newAdd}
                            setNewAdd={setNewAdd}
                            setAddSelect={setAddSelect}
                            setHouseNum={setHouseNum}
                            setPostCode={setPostCode}
                            onSubmit={onSubmit}
                            Address={Address}
                            houseNum={houseNum}
                            postcodes={postcodes}
                            setAddStat={setAddStat}
                            apiAddresData={apiAddresData}
                            boxs={boxs}
                            addSelect={addSelect}
                            setBoxs={setBoxs}
                            singleAddCard={singleAddCard}
                            apiPostCode={apiPostCode}
                            setShowBox={setShowBox}
                            setSingleAddCard={setSingleAddCard}
                            setApiAddresData={setApiAddresData}
                            multiAdd={multiAdd}
                            setMultiAdd={setMultiAdd}
                        />
                    </div>
                </div>
                <div className='singup_page__image_content'>
                    <img src={register_side_img} alt='' />
                </div>
            </div>
        </>
    )
}