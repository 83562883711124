import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cholocate_text_with_leafe_icon from '../../assets/images/cholocate_text_with_leafe_icon.png'
import the_natural_taste_of_india from '../../assets/images/the_natural_taste_of_india.png'
import { available_itmes } from '../../constants';
import {products} from '../../products'
import './ChocolatePage.scss'

export const ChocolatePage = () => {
    const navigate = useNavigate();
    const redirectToProdDescription = (url:any) => {
        // navigate("/ProductDescription", { state: item })
        window.open(url)
    }  
    const [selectedProduct, setSelectedProduct] = useState('Chocolates')

      
    return <div className='chocolate_container'>
        <h2 className="fa-c ft-w6">Vrishkar’s Palm Products</h2>
        <div className="fa-c"><i className="ft-w5">
            “Our natural products will supply the essential vitamins and minerals for a healthy living”</i>
        </div>
        <div className="fa-c">
            <img src={cholocate_text_with_leafe_icon} className='choco_tasty_img' alt='' height='auto' />
        </div>
        <div>
            <div className="fa-c content_flex justify_content_sa">
                <div className='content_flex chocolate_container__small-card-container'>
                    {available_itmes.map(({ text }, index) => {
                        return <div
                            onClick={() => setSelectedProduct(text)}
                            className={`chocolate_container__small-card fs_18 ${text === selectedProduct ?
                             'selected_product' : ''}`} key={index} >
                            {text}
                        </div>
                    })}
                </div>
            </div>
            <div className='row my-4 product_details_container '>
                {/* {products.filter((data:any)=>data.category===selectedProduct).map((item, i) => */}
                {products.map((item, i) =>
                    <div  className='col-md-6 content_flex content_items_c justify_content_sa product_content'
                    >
                        <div className="card product_card">
                            {/* <img className="card-img-top" src={dark_heart_chocolate} alt="Card cap" /> */}
                            <img className="card-img-top" src={item.picSrc} alt="Card cap" />
                            <div className="card-body">
                                <h5 className="card-title fs_20 ft-w6 my-2">{item.product_name}</h5>
                                    <p>{item.description}</p>
                                <p className="card-text ft-w6 fs_18 my-3">{item.price} Rs </p>
                                {/* <p className="card-text ft-w6 fs_18 my-3"> {item.quantity}</p> */}
                                <button className="btn add_to_cart_button fs_20 ft-w6" onClick={()=>redirectToProdDescription(item.redirectUrl)}>Buy now</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className='the-tast_of_india fa-c '>
            <img src={the_natural_taste_of_india} alt='' />
        </div>
    </div>
}