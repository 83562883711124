import { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import vrishkar_logo from '../../../assets/images/vrishkar_logo.svg'
import './HeaderNavBar.scss';

export const HeaderNavBar = () => {
    const [navItem, setNavItem] = useState('Home')
    const navItems = [
     
        {
            text: 'Home',
            route: '/'
        },
        {
            text: 'Nutrition Info',
            route: '/palm-jaggery-nutritional-info'
            // route: '/'
        },
        {
            text: 'Shop Now',
            route: '/products'
            // route: '/'
        },
        {
            text: 'Blog',
            route: '/blog/Benefits-Of-Palm-Jaggery'
            // route: '/'
        }
        
    ]

    const navigator = useNavigate()
    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light"
            className="header_navbar_container">
            <Container>
                <Navbar.Brand  onClick={() => {
                                navigator('/')
                            }} >
                    <img src={vrishkar_logo} alt=""
                        className="vishkar_logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        
                    </Nav>
                    <Nav>
                        {navItems.map((navEle: any, index: any) => {
                            return <Nav.Link className="nav-text"
                                onClick={() => {
                                    setNavItem(navEle.text)
                                    navigator(navEle.route)
                                }}
                            >
                                {navEle.text}
                                <div className={navItem === navEle.text ? 'border_bottom' : ''} />
                            </Nav.Link>
                        })}
                        {/* <Nav.Link className="nav-text" eventKey={4}
                            onClick={() => {
                                // navigator('/checkout')
                                navigator('/')
                            }}
                        >
                            <img src={cart_icon} alt='' className="cart_icon" />
                        </Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
