import PalmJaggeryfamily from "../../assets/images/PalmJaggeryLiquid/PalmJaggeryfamily.png";

import PalmJaggeryLanding from "../../assets/images/PalmJaggery/Palm_Jaggery_Landing.jpeg";
import PalmJaggerynectarbox from "../../assets/images/PalmJaggery/Palm_Jaggery.jpeg";
// import PalmJaggerynectarbox from "../../assets/images/PalmJaggery/Palm_Jaggery_tea.jpeg";
import PalmJaggeryLiquidBenifits from "../../assets/images/PalmJaggeryLiquid/PalmJaggeryLiquidBenifits.jpeg";

import PalmJaggeryNutrition from "../../assets/images/PalmJaggeryLiquid/PalmJaggeryNutrition.jpeg";

import empty_star_icon from "../../assets/images/empty_star_icon.png";
import jaggary from "../../assets/images/jaggary.png";
import the_natural_taste_of_india from "../../assets/images/the_natural_taste_of_india.png";
import perrson_icon from "../../assets/images/perrson_icon.png";
import { useEffect, useState } from "react";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
} from "@mui/material";
import { Carousel } from "react-bootstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ProductInformationPage } from "./ProductInformationPage";

import "./ProductDescriptionPage.scss";
import "../NuteritionPage/NutritionPage.scss";

import '../FAQsPage/FAQsPage.scss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Faqs } from '../../constants/allConstants';
import React from "react";


export const ProductJaggeryDescriptionPage = () => {
  const [prodImg, setProdImage] = useState(PalmJaggeryfamily);
  const [weight, setWeight] = useState(10);
  const [price, setPrice] = useState("496");
  const [flavourType, setFlavourType] = useState<any>(1);
  const [quantity, setQuantity] = useState(1);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };
  const [arrow, setArrow] = useState(0)
  // const navigate = useNavigate()
  const HealthBenifits = [
    {
      index: 1,
      value: " Our Palm Jaggery helps in Boosts energy and immunity",
    },
    { index: 2, value: " Date Palm Jaggery are Diabetic Friendly" },
    {
      index: 3,
      value: " Palm Jaggery has Rich source of vitamins and minerals",
    },
    { index: 4, value: " Date Palm Jaggery are Highly nutritions" },
    { index: 5, value: " Palm Jaggery helps Restores healthy digestion" },
    { index: 6, value: " Date Palm Jaggery Helps in weight loss" },
    { index: 7, value: " Palm Jaggery Eases joint pain" },
    {
      index: 8,
      value:
        " Date Palm Jaggery helps to Relieves common ailments like cold and cough",
    },
    { index: 9, value: " Treats menstrual pain" },
  ];
  const [productDescription, setProductDescription] = useState(false);

  const redirectToProdDescription = (url: any) => {
    // navigate("/ProductDescription", { state: item })
    window.open(url);
  };

  return (
    <div className="banner_container">
      <div className="product_description_container">
        <div className="content_flex">
          <div className="content_flex desktop_img content_item_fs ">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "",
                height: 904,
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
              className="mx-3"
            >
              <div>
                <img
                  src={PalmJaggeryLanding}
                  alt=""
                  onClick={() => setProdImage(PalmJaggeryLanding)}
                  width={120}
                  height={120}
                />
              </div>
              <div>
                <img
                  src={PalmJaggeryfamily}
                  onClick={() => setProdImage(PalmJaggeryfamily)}
                  alt=""
                  width={120}
                  height={120}
                />
              </div>
              <div>
                <img
                  src={PalmJaggeryLiquidBenifits}
                  onClick={() => setProdImage(PalmJaggeryLiquidBenifits)}
                  alt=""
                  width={120}
                  height={120}
                />
              </div>
              <div>
                <img
                  src={PalmJaggerynectarbox}
                  onClick={() => setProdImage(PalmJaggerynectarbox)}
                  alt=""
                  width={120}
                  height={120}
                />
              </div>
              <div>
                <img
                  src={PalmJaggeryNutrition}
                  onClick={() => setProdImage(PalmJaggeryNutrition)}
                  alt=""
                  width={120}
                  height={120}
                />
              </div>
            
            </div>
            <div style={{ marginRight: 20 }}>
              {prodImg && <img src={prodImg} alt="" width={600} height={600} />}
            </div>
          </div>
          <div className="organic_dark_chocolate">
            {/* <h2 className='ft-w6 desktop_version'>Organic Dark Chocolates</h2> */}
            <h2 className="ft-w6 desktop_version">
              {
                "Palm Jaggery- Wild Date Palm Jaggery | Nolen gur - Patali Gur | khejur Gur - Palm Sugar | Natural - Organic Palm Jaggery"
              }
            </h2>
            <div className="vrishkar_palm_prod_rating">
              <p>Vrishkar’s Palm Products</p>
              {/* <Rating name="simple-controlled" size='small' /> */}
            </div>
            {/* <h2 className='ft-w6 mob_version'>Organic Dark Chocolates</h2> */}
            <div className="mob_curosel">
              <div className="curosel_indicators">
                <Carousel
                  className="curosel_container"
                  activeIndex={index}
                  onSelect={handleSelect}
                  interval={10000}
                >
                  {[1, 2, 3, 4, 5].map((e) => (
                    <Carousel.Item>
                      <img
                        // className="d-block w-100"
                        className="choco_pista_img"
                        src={PalmJaggeryfamily}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="desktop_version">
              <p className="fs_14 mob_margin">Price:</p>
              <div>
                <p className="primary_color_text fs_24">
                  ₹ {price} Rs
                  {/* <sup className='fs_8'>
                                ( Inclusive of all taxes)</sup> */}
                </p>
              </div>
            </div>
            <div className="mob_version">
              <p className="fs_20 mt-4 d-flex">
                Price:{" "}
                <span className="mx-2 primary_color_text">₹ {price} Rs</span>{" "}
              </p>
              <p className="primary_color_text inclisive_all ">
                ( Inclusive of all taxes)
              </p>
            </div>
            <div className="inclisive_all">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">250 Grams</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={weight}
                  label="weight"
                  // size='small'
                  onChange={(e) => {
                    setWeight(Number(e.target.value));
                    if (Number(e.target.value) === 10) {
                      setPrice("496");
                    } else if (Number(e.target.value) === 20) {
                      setPrice("896");
                    } else {
                      setPrice("1196");
                    }
                  }}
                >
                  <MenuItem value={10}>250 Grams</MenuItem>
                  <MenuItem value={20}>500 Grams</MenuItem>
                  <MenuItem value={30}>750 Grams</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className='mt-3' >
                        <div className='fs_20'>Product Details </div> */}
            {/* <div className='fs_20 green_color mob_version'>
                            4500 Rating 5 Answered Questions</div> */}
            {/* </div> */}

            <p className="fs_16 ft-w6 mt-2">
              Amazing Health Benefits of Palm Jaggery
            </p>
            {HealthBenifits.map(({ value }: any) => (
              <p className="ft-light fs_12">
                <img src={empty_star_icon} width={18} alt="" /> <b>{value}</b>
              </p>
            ))}

            <div className="mob_version divider_margin" />

            {/* <h6 className='fs_16 ft-w6 mt-2'>Select Flavors</h6>
                    <div className='row flavours_content' >
                        {[1, 2, 3, 4, 5, 6].map((flavours, index) =>
                            <div
                                className={`flavours_types ${flavourType === flavours ? 'active_flavours_types' : ''}`}
                                onClick={() => setFlavourType(flavours)} >Pista</div>
                        )}
                    </div> */}
            {/* <div className='desktop_version divider_margin' style={{ borderWidth: '1px' }} />
                    <div className='content_flex mt-4 product_quantity'>
                        <div>Quantity</div>
                        <div className='increment_decrement'>
                            <div className='cursor' onClick={() => setQuantity(prev => prev + 1)} >+</div>
                            <div className='primary_color_text'>{quantity}</div>
                            <div className='cursor' onClick={() => setQuantity(prev => prev - 1)} >-</div>
                        </div>
                    </div> */}

            <div className="text-center">
              <button
                className="btn add_to_cart_button"
                onClick={() =>
                  redirectToProdDescription(
                    "https://www.amazon.in/dp/B0BTXJQ9C5?th=1"
                  )
                }
              >
                Buy Now
              </button>
            </div>

            <div className="mob_version divider_margin" />
          </div>
        </div>
        {/* <h4 className='ft-w6 top_selling'>Top Selling Sponserd Products</h4> */}
        {/* <div className='desktop_version'>
                <Grid container direction={'row'} className="landing_page__stars_container">
                    <Grid
                        item
                        xs={8}
                        container
                        direction={'row'}
                        className="landing_page__curosel "
                    >
                        <Carousel className='cusrosel_container' >
                            <Carousel.Item>
                                <Grid container direction={'row'} spacing={2}>
                                    {[1, 2, 3].map((data: any, index: any) => (
                                        <Grid item xs={4} className="individual_reviews" key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div>
                                                    <img
                                                        alt=""
                                                        src={jaggary}
                                                        key={index}
                                                        className="individual_reviews__rating"
                                                    />
                                                    <h5 className='ft-w6 text-center mt-2' >Jaggery</h5>
                                                    <p className='mx-4 text-center individual_reviews__sub_text' >Lorem ipsum dolor sit amet,
                                                        consectetuer adipiscing elit.
                                                        Aenean commodo ligula eget
                                                    </p>
                                                    <div className='text-center'>
                                                        <Rating name="simple-controlled" size='small' /> (2,345)
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Grid container direction={'row'} spacing={2}>
                                    {[1, 2, 3].map((data: any, index: any) => (
                                        <Grid item xs={4} className="individual_reviews" key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div>
                                                    <img
                                                        alt=""
                                                        src={jaggary}
                                                        key={index}
                                                        className="individual_reviews__rating"
                                                    />
                                                    <h6 className='ft-w6 text-center mt-2' >Jaggery</h6>
                                                    <p className='mx-4 text-center individual_reviews__sub_text '>Lorem ipsum dolor sit amet,
                                                        consectetuer adipiscing elit. Aenean commodo ligula eget

                                                    </p>
                                                    <div className='text-center'>
                                                        <Rating name="simple-controlled" size='small' /> (2,345)
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Carousel.Item>
                        </Carousel>
                    </Grid>
                </Grid>
            </div> */}
        {/* mob_curosel */}
        {/* <div className='mob_version mob_curosel'>
                <Carousel className='curosel_content'>
                    <Carousel.Item>
                        <Grid container direction={'row'} spacing={2}>
                            {[1].map((data: any, index: any) => (
                                <Grid item xs={4} className="individual_reviews" key={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <img
                                                alt=""
                                                src={jaggary}
                                                key={index}
                                                className="individual_reviews__rating"
                                            />
                                            <h5 className='ft-w6 text-center mt-2' >Jaggery</h5>
                                            <p className='mx-4 text-center individual_reviews__sub_text' >Lorem ipsum dolor sit amet,
                                                consectetuer adipiscing elit.
                                                Aenean commodo ligula eget
                                            </p>
                                            <div className='text-center'>
                                                <Rating name="simple-controlled" size='small' /> (2,345)
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Grid container direction={'row'} spacing={2}>
                            {[1].map((data: any, index: any) => (
                                <Grid item xs={4} className="individual_reviews" key={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <img
                                                alt=""
                                                src={jaggary}
                                                key={index}
                                                className="individual_reviews__rating"
                                            />
                                            <h6 className='ft-w6 text-center mt-2' >Jaggery</h6>
                                            <p className='mx-4 text-center individual_reviews__sub_text '>Lorem ipsum dolor sit amet,
                                                consectetuer adipiscing elit. Aenean commodo ligula eget

                                            </p>
                                            <div className='text-center'>
                                                <Rating name="simple-controlled" size='small' /> (2,345)
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Carousel.Item>
                </Carousel>
            </div> */}

        <div className="desktop_version">
          <h4 className="ft-w6">Product Decription</h4>

          <p>
            Vrishkar Indian wild date raw sap is gently boiled to give
            deliciously sweet Palm Jaggery and Palm Jaggery Liquid.
          </p>

          <p>
            {" "}
            Palm Jaggery and Our Palm Jaggery Liquid will not create any stress
            on pancreas to release more insulin. with its Low GI you are assured
            of no spike in blood glucose levels and a natural alternative to
            sugar in tea, coffee and sweets and it has all necessary vitamins
            and minerals need for you healthy living.
          </p>
        </div>
        <Divider className="mob_version divider_margin" />
        <div className="mob_version">
          <h4 className="ft-w6">Product Decription</h4>
          <div className="content_flex align-items-center">
            <p>
              Vrishkar Indian wild date raw sap is gently boiled to give
              deliciously sweet Palm Jaggery and Palm Jaggery Liquid.
            </p>

            <p>
              {" "}
              Palm Jaggery and Our Palm Jaggery Liquid will not create any
              stress on pancreas to release more insulin. with its Low GI you
              are assured of no spike in blood glucose levels and a natural
              alternative to sugar in tea, coffee and sweets and it has all
              necessary vitamins and minerals need for you healthy living.
            </p>

            <IconButton onClick={() => setProductDescription(true)}>
              <ArrowForwardIosIcon className="mx-2" />
            </IconButton>
          </div>
          {productDescription && <ProductInformationPage />}
          <ProductInformationPage />
        </div>

        <div className="mt-2 desktop_version">
          <ProductInformationPage />
        </div>

        {/* <div className="row justify_content_sa mt-4 ">
          <div className="reviews col-md-4 col-xs-12 desktop_version">
            <h4 className="ft-w6 mb-3">Review product</h4>
            <p>Share your thoughts with customer</p>
            <button className="btn product_review">Write product review</button>
          </div>
          <div className="reviews_images col-md-8 col-xs-12">
            <div className="review_data">
              <h4 className="ft-w6 mb-3">Top reviews</h4>
              {[1, 2, 3].map((e) => (
                <div className="mt-4">
                  <div>
                    <img src={perrson_icon} alt="" /> Terence Fernandes
                  </div>
                  <div className="content_flex">
                    <Rating name="simple-controlled" />
                    <div className="mx-2 fs_12">Good Product</div>
                  </div>
                  <div className="ft-w5 fs_12">
                    Reviewed in India on 14 September 2020
                  </div>
                  <div className="ft-w5 orange_color fs_14">
                    Verified Purchase
                  </div>
                  <div className="ft-w6 fs_12">
                    My friend really like the chocolates in the box And they
                    were not melted and it was overall a good gift for my
                    friend.
                  </div>
                  <p
                    className="ft-w1 fs_12 "
                    style={{ fontStyle: "normal", fontWeight: 100 }}
                  >
                    10 people found this helpful
                  </p>
                  <p>
                    <span className="help_full mx-3">Helpful</span> |
                    &nbsp;&nbsp; Report Abuse
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Divider className="mob_version divider_margin mob_version " />
        <div className="mob_version">
          <div className="d-flex justify-content-between py-2 ">
            <div className="fs_18 ">See all reviews</div>
            <div>
              <ArrowForwardIosIcon />
            </div>
          </div>
        </div>
        <Divider className="mob_version divider_margin" />

        <div className="text-center mob_version">
          <button className="btn review_btn d-flex justify-content-between align-items-center">
            <div className="fs_18">Write a review</div>{" "}
            <ArrowForwardIosIcon sx={{ fontSize: 20 }} />{" "}
          </button>
        </div> */}
          <Divider  />
        <div className="the-tast_of_india fa-c desktop_version">
          <img src={the_natural_taste_of_india} alt="" />
        </div>
      </div>
    </div>
  );
};
