import axios from 'axios'
import { getAddressUrl } from '../constants/allConstants'

export const userRegistrationAddressApi = async (
  postcodeNumber: any,
  houseNumber: any
): Promise<any> => {
  try {
    // -nOfbVJpVUaxAaJZyNvjpw33856
    return await axios.get(
      `${getAddressUrl}/${postcodeNumber}/${houseNumber}?api-key=FAI4a7aEaE2tPLkCwIOssA35048&expand=true`
    )
  } catch (exception) {
    console.log(exception)
    throw exception
  }
}
