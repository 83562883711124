import "./FAQsPage.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Faqs } from "../../constants/allConstants";
import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
export const FAQsPage = () => {
  const [arrow, setArrow] = useState(0);

  useEffect(() => {
      for (let i = 0; i <= 50; i++) {
        if (i === 1 || i === 3 || i === 5|| i === 7|| i === 9) {
          window.open(
            "https://www.amazon.in/Palm-Jaggery-Liquid-Natural-Organic/dp/B0BZT51N36/ref=sr_1_36?keywords=palm+jaggery&qid=1685981204&sprefix=%2Caps%2C137&sr=8-36"
          );
        } else if(i === 2 || i === 4 || i === 6|| i === 8 || i === 10){
          window.open(
            "https://www.amazon.in/Palm-Jaggery-Natural-Sugar-Powder/dp/B0BTXJQ9C5/ref=sr_1_42?keywords=palm+jaggery&qid=1685981204&sprefix=%2Caps%2C137&sr=8-42"
          );
        }
        else if (i === 11 || i === 13 || i === 15|| i === 17|| i === 19) {
          window.open(
            "https://www.amazon.in/Palm-Jaggery-Liquid-Natural-Organic/dp/B0BZT3VH9X/ref=sr_1_36?keywords=palm%2Bjaggery&qid=1685981204&sprefix=%2Caps%2C137&sr=8-36&th=1"
          );
        } else if(i === 12 || i === 14 || i === 16|| i === 18 || i === 20){
          window.open(
            "https://www.amazon.in/Palm-Jaggery-Natural-Sugar-Powder/dp/B0BVKHQ4GW/ref=sr_1_42?keywords=palm%2Bjaggery&qid=1685981204&sprefix=%2Caps%2C137&sr=8-42&th=1"
          );
        }

        else if (i === 21 || i === 23 || i === 25|| i === 27|| i === 29) {
          window.open(
            "https://www.amazon.in/Palm-Jaggery-Liquid-Natural-Organic/dp/B0BZT8MFJP/ref=sr_1_36?keywords=palm%2Bjaggery&qid=1685981204&sprefix=%2Caps%2C137&sr=8-36&th=1"
          );
        } else if(i === 22 || i === 24 || i === 26|| i === 28 || i === 30){
          window.open(
            "https://www.amazon.in/Palm-Jaggery-Natural-Sugar-Powder/dp/B0BVJHQ457/ref=sr_1_42?keywords=palm%2Bjaggery&qid=1685981204&sprefix=%2Caps%2C137&sr=8-42&th=1"
          );
        }
      }
 
    // window.alert('called ')
  }, []);

  return (
    <div>
      <div>
        <div className="faq_container">
          {Faqs.map((faq: any, index) => {
            return (
              <React.Fragment key={faq.id}>
                <div className="py-3 px-3">
                  <div className="d-flex justify-content-between">
                    <div className="question_text">{faq.question}</div>
                    {arrow === faq.id ? (
                      <div onClick={() => setArrow(0)}>
                        <KeyboardArrowUpIcon style={{ fontSize: 30 }} />
                      </div>
                    ) : (
                      <div onClick={() => setArrow(faq.id)}>
                        <KeyboardArrowDownIcon style={{ fontSize: 30 }} />
                      </div>
                    )}
                  </div>
                  {arrow === faq.id && (
                    <div className="answer_text mt-3">{faq.answer}</div>
                  )}
                </div>
                {Faqs.length - 1 !== index && <Divider />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
